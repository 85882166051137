import { FormEvent, useEffect, useState } from 'react';
import { Switch, Listbox } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { CheckOrCrossIcon, Button, Table, Input, CommonRankerConfig } from '@src/components';
import { useNavigate, useParams } from 'react-router';
import { useCreateTransactionRanker, useGetTransactionRankerById, useUpdateTransactionRanker } from '@src/hooks';
import {
  JsonProximityRankingRule,
  JsonProximityTransactionRanker,
  JsonProximityRankingAlgorithms,
  TransactionRankerType
} from '@src/models';
import classNames from 'classnames';
import routes from '@src/routes';

const RemoveRuleButton = ({ row, removeRowClicked }: any) => (
  <Button
    small
    danger
    onClick={() => removeRowClicked(row.original as JsonProximityTransactionRanker)}
    className="float-right"
  >
    Remove
  </Button>
);

const columns = [
  {
    id: 1,
    Header: 'JSONPATH',
    accessor: 'path'
  },
  {
    id: 2,
    Header: 'Algorithm',
    accessor: 'algorithm'
  },
  {
    id: 3,
    Header: 'Score',
    accessor: 'score',
  },
  {
    id: 4,
    Header: 'Should sort string',
    accessor: 'sort_string',
    Cell: CheckOrCrossIcon
  },
  {
    id: 5,
    Header: 'Should ignore case',
    accessor: 'ignore_case',
    Cell: CheckOrCrossIcon
  },
  {
    id: 6,
    Cell: RemoveRuleButton,
    idAccessor: 'id'
  }
];

const algorithmMap: { [key in JsonProximityRankingAlgorithms]: string } = {
  [JsonProximityRankingAlgorithms.HammingDistance]: 'Hamming Distance',
  [JsonProximityRankingAlgorithms.Levenshtein]: 'Levenshtein',
  [JsonProximityRankingAlgorithms.LongestCommonSubsequence]: 'LCS - subsequence',
  [JsonProximityRankingAlgorithms.LongestCommonSubstring]: 'LCS - substring'
};

type ListBoxOption = {
  id: number;
  name: string;
  value: JsonProximityRankingAlgorithms;
};

const listBoxOptions: ListBoxOption[] = [
  {
    id: 0,
    name: algorithmMap[JsonProximityRankingAlgorithms.HammingDistance],
    value: JsonProximityRankingAlgorithms.HammingDistance
  },
  {
    id: 1,
    name: algorithmMap[JsonProximityRankingAlgorithms.Levenshtein],
    value: JsonProximityRankingAlgorithms.Levenshtein
  },
  {
    id: 2,
    name: algorithmMap[JsonProximityRankingAlgorithms.LongestCommonSubsequence],
    value: JsonProximityRankingAlgorithms.LongestCommonSubsequence
  },
  {
    id: 3,
    name: algorithmMap[JsonProximityRankingAlgorithms.LongestCommonSubstring],
    value: JsonProximityRankingAlgorithms.LongestCommonSubstring
  }
];

const newRuleInitialData: JsonProximityRankingRule = {
  path: '',
  score: 0,
  sort_string: false,
  ignore_case: false,
  algorithm: null
};

export const JsonProximityRanker = ({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate();
  const { id: rankerId } = useParams() as { id: string };

  const { mutateAsync: saveNewRanker } = useCreateTransactionRanker();
  const { mutateAsync: updateRanker } = useUpdateTransactionRanker();

  const { data: rankingRule, isLoading } = useGetTransactionRankerById(rankerId);

  const [ranker, setRanker] = useState<JsonProximityTransactionRanker>({
    id: '',
    type: TransactionRankerType.JsonProximityRanking,
    domain_pattern: '',
    ticket_id: '',
    apply_to_organization: false,
    enabled: true,
    params: {
      rules: []
    }
  });

  useEffect(() => {
    if (!rankingRule) return;

    setRanker(rankingRule as JsonProximityTransactionRanker);
  }, [rankingRule]);

  const [newFieldRule, setNewFieldRule] = useState<JsonProximityRankingRule>(newRuleInitialData);

  const saveRanker = (e: FormEvent) => {
    e.preventDefault();

    if (ranker.params.rules.length === 0) {
      alert('Please add at least one rule.');
      return;
    }

    if (isEdit) {
      updateRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    } else {
      saveNewRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    }
  };

  const removeRowClicked = (rule: JsonProximityRankingRule) => {
    setRanker({ ...ranker, params: { rules: ranker.params.rules.filter(f => f.path !== rule.path) } });
  };

  const addNewRule = () => {
    if (newFieldRule.path === '' || ranker.params.rules.some(r => r.path === newFieldRule.path)) {
      alert('Json path should be unique and not an empty string');
      return;
    }

    setRanker({
      ...ranker,
      params: {
        rules: [...ranker.params.rules, newFieldRule]
      }
    });

    setNewFieldRule(newRuleInitialData);
  };

  const selectOption = (value: string) => {
    setNewFieldRule(prev => ({
      ...prev,
      algorithm: value as JsonProximityRankingAlgorithms
    }));
  };

  if (isLoading) return <></>;

  return (
    <form onSubmit={saveRanker}>
      <div className="space-y-6">
        <CommonRankerConfig
          title="JSON Proximity Ranking"
          description={
            <>
              Configure ranking rules for <i>application/json</i> request content.
            </>
          }
          ranker={ranker}
          updateRanker={ranker => setRanker(ranker as JsonProximityTransactionRanker)}
        />

        <div className="py-4">
          <div className="w-full border-t border-gray-300"></div>
        </div>

        <div className="space-y-2">
          <div className="space-y-1">
            <div className="mt-6 flex gap-4 items-center">
              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="JSON Path"
                  type="text"
                  name="jsonpath"
                  id="jsonpath"
                  placeholder="example.data"
                  value={newFieldRule.path}
                  onChange={e => setNewFieldRule({...newFieldRule, path: e.target.value})}
                />
              </div>

              <Listbox
                as="div"
                className="flex flex-col gap-1 relative"
                value={newFieldRule.algorithm || ''}
                onChange={selected => selectOption(selected)}
              >
                <Listbox.Label className="block text-sm font-medium text-gray-700 -mt-1">
                  Algorithm Picker
                </Listbox.Label>
                <Listbox.Button as="div">
                  <button
                    type="button"
                    className="w-40 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  >
                    {newFieldRule.algorithm ? algorithmMap[newFieldRule.algorithm] : 'Select Algorithm'}
                  </button>
                </Listbox.Button>
                <Listbox.Options
                  className="absolute top-16 bg-white border border-gray-300 rounded-md shadow-sm font-medium text-gray-700">
                  {listBoxOptions.map(listBoxOption => (
                    <Listbox.Option
                      key={listBoxOption.id}
                      value={listBoxOption.value}
                      className="h-10 w-48 flex justify-start items-center p-4 hover:bg-gray-50 cursor-pointer"
                    >
                      {listBoxOption.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="score"
                  type="text"
                  name="score"
                  id="score"
                  placeholder="100"
                  value={newFieldRule.score}
                  onChange={e => setNewFieldRule({...newFieldRule, score: Number(e.target.value)})}
                />
              </div>

              <Switch.Group as="div">
                <Switch.Label className="block text-sm font-medium text-gray-700 -mt-1" passive>
                  Sort String
                </Switch.Label>
                <dd className="pt-2 text-sm text-gray-900 sm:mt-1">
                  <Switch
                    checked={newFieldRule.sort_string}
                    onChange={(checked: boolean) => setNewFieldRule({...newFieldRule, sort_string: checked})}
                    className={classNames(
                      newFieldRule.sort_string ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        newFieldRule.sort_string ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </dd>
              </Switch.Group>


              <Switch.Group as="div">
                <Switch.Label className="block text-sm font-medium text-gray-700 -mt-1" passive>
                  Ignore Case
                </Switch.Label>
                <dd className="pt-2 text-sm text-gray-900 sm:mt-1">
                  <Switch
                    checked={newFieldRule.ignore_case}
                    onChange={(checked: boolean) => setNewFieldRule({...newFieldRule, ignore_case: checked})}
                    className={classNames(
                      newFieldRule.ignore_case ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        newFieldRule.ignore_case ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </dd>
              </Switch.Group>


              <div className="ml-auto">
                <Button icon={<PlusIcon/>} secondary onClick={addNewRule}>
                  Add Rule
                </Button>
              </div>
            </div>
            <div className="border-b border-gray-200">
              <Table columns={columns} data={ranker.params?.rules} removeRowClicked={removeRowClicked}/>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            onClick={() => navigate(routes.CONFIGURATIONS_RANKING)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
