import { useEffect, useState } from 'react';

type JsonPreviewerProps = {
  content?: Blob;
  contentText?: unknown;
  contentType?: string;
};

const formatJson = (txt: unknown): string => {
  try {
    return JSON.stringify(typeof txt === 'string' ? JSON.parse(txt) : txt, null, 2);
  } catch {
    return `${txt}`;
  }
};

export const JsonPreviewer = ({ content, contentText, contentType }: JsonPreviewerProps) => {
  const [txt, setTxt] = useState<unknown>(contentText || '');

  if (!contentText) {
    content?.text().then(setTxt);
  }

  useEffect(() => {
    if (!contentText) {
      content?.text().then(setTxt);
    } else {
      setTxt(contentText);
    }
  }, [content, contentText]);

  const formatted = formatJson(txt).replaceAll('\\n', '<br />');

  return (
    <>
      <p className="italic">{contentType}</p>
      <p className="max-h-96 mt-2 p-2 w-full border border-gray-300 rounded-md font-mono text-xs whitespace-pre overflow-auto">
        {formatted}
      </p>
    </>
  );
};
