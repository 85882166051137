import { ReactNode, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { ExclamationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export type CommandPaletteItem = {
  id: string;
  name: string;
  description?: string;
  color?: string;
  icon?: ReactNode;
  key?: string;
};

type CommandPaletteProps = {
  items: CommandPaletteItem[];
  onItemClick: (item: CommandPaletteItem) => void;
};

export const CommandPalette = ({ items, onItemClick }: CommandPaletteProps) => {
  const [query, setQuery] = useState('');

  const filteredItems =
    query === ''
      ? items
      : items.filter(item => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox value={items[0]} onChange={() => {}}>
      <div className="relative">
        <MagnifyingGlassIcon
          className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <Combobox.Input
          className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
          placeholder="Search..."
          onChange={event => setQuery(event.target.value)}
        />
      </div>

      {filteredItems.length > 0 && (
        <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
          {filteredItems.map(item => (
            <Combobox.Option
              key={item.key || item.id}
              value={item}
              className={({ active }) =>
                classNames('flex cursor-pointer select-none rounded-xl p-3', active && 'bg-gray-100')
              }
              onClick={() => onItemClick(item)}
            >
              {({ active }) => (
                <>
                  <div
                    className={classNames(
                      'flex h-10 w-10 flex-none items-center justify-center rounded-lg',
                      item.color
                    )}
                  >
                    {item.icon}
                  </div>
                  <div className="ml-4 flex-auto">
                    <p className={classNames('text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700')}>
                      {item.name}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description || '' }}
                      className={classNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}
                    />
                  </div>
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}

      {query !== '' && filteredItems.length === 0 && (
        <div className="py-14 px-6 text-center text-sm sm:px-14">
          <ExclamationCircleIcon type="outline" name="exclamation-circle" className="mx-auto h-6 w-6 text-gray-400" />
          <p className="mt-4 font-semibold text-gray-900">No results found</p>
          <p className="mt-2 text-gray-500">No items found for this search term. Please try again.</p>
        </div>
      )}
    </Combobox>
  );
};
