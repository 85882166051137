import { useParams } from 'react-router-dom';
import {
  useGetTransactionDetails,
  useGetTransactionResponseData,
  useResponseDataReplace,
  useGetTransactionRequestData,
  useUpdateTransactionPreload
} from '@src/hooks';
import { DataPreviewer, JsonPreviewer, Checkbox, HeadersPreviewer } from '@src/components';
import { Transaction } from '@src/models';

export const TransactionDetails = () => {
  const params = useParams<{ id: string }>() as { id: string };

  const { data: transaction } = useGetTransactionDetails(params.id);
  const { data: transactionResponseData } = useGetTransactionResponseData(params.id);
  const { data: transactionRequestData } = useGetTransactionRequestData(params.id);

  const { mutate: replaceData } = useResponseDataReplace(params.id);
  const { mutate: updateTransactionData } = useUpdateTransactionPreload(params.id);

  const updateTransactionPreload = (transaction: Transaction, preload: boolean) => {
    if (!transaction) return;
    transaction.preload = preload;
    updateTransactionData({ transaction });
  };

  const onFileUploaded = (file: File, applyToAllTransactions: boolean) => {
    if (!file) return;

    const formData = new FormData();
    formData.append('content_to_update', file);

    replaceData({ formData, applyToAllTransactions });
  };

  if (!transaction) {
    return <>Couldn't load a page. TODO: Add error page...</>;
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Transaction Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Request / Response information...</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Preload</dt>
            <Checkbox
              label=""
              description="Tick ✓ to enable preload"
              isChecked={transaction.preload}
              onChange={preload => {
                updateTransactionPreload(transaction, preload);
              }}
            />
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Id</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{transaction.id}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Method</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{transaction.method}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Url</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{transaction.url}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status code</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{transaction.status_code}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Set-Cookie</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {transaction.set_cookie && <JsonPreviewer contentText={transaction.set_cookie} />}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Annotations</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {transaction.annotations ? (
                <p className="mt-2 p-2 w-full border border-gray-300 rounded-md font-mono text-xs whitespace-pre overflow-auto">
                  {JSON.stringify(transaction.annotations, null, 2)}
                </p>
              ) : (
                <>No data</>
              )}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Search key</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {transaction.search_key ? (
                <p className="mt-2 p-2 w-full border border-gray-300 rounded-md font-mono text-xs whitespace-pre overflow-auto">
                  {JSON.stringify(transaction.search_key, null, 2)}
                </p>
              ) : (
                <>No data</>
              )}
            </dd>
          </div>
          <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Request header</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <HeadersPreviewer headers={transaction.request_headers} />
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Request data</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <DataPreviewer replaceable={false} {...transactionRequestData} originalUrl={transaction.url} />
            </dd>
          </div>
          <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Response header</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <HeadersPreviewer headers={transaction.response_headers} />
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Response data</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <DataPreviewer
                replaceable
                {...transactionResponseData}
                originalUrl={transaction.url}
                onFileUploaded={onFileUploaded}
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
