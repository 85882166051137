import request, { apiRequest } from '../request';
import { AnyEditorRule } from '@src/models';

const API_ENDPOINTS = {
  EDITOR_RULES: '/rules/editor'
};

export const getEditorRules = async (): Promise<AnyEditorRule[]> =>
  (await apiRequest(request.get(API_ENDPOINTS.EDITOR_RULES))).content;

export const getEditorRuleById = async (editorRuleId: string): Promise<AnyEditorRule | undefined> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.EDITOR_RULES}/${editorRuleId}`))).content;

export const createEditorRule = async (editorRule: AnyEditorRule): Promise<AnyEditorRule> => {
  return (await apiRequest(request.post(API_ENDPOINTS.EDITOR_RULES, editorRule))).content;
};

export const updateEditorRule = async (editorRule: AnyEditorRule): Promise<AnyEditorRule> => {
  return (await apiRequest(request.put(`${API_ENDPOINTS.EDITOR_RULES}/${editorRule.id}`, editorRule))).content;
};

export const deleteEditorRule = async (editorRule: string): Promise<AnyEditorRule | undefined> =>
  (await apiRequest(request.delete(`${API_ENDPOINTS.EDITOR_RULES}/${editorRule}`))).content;
