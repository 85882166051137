import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getTransactionRankerById,
  getTransactionRankers,
  createTransactionRanker,
  deleteTransactionRanker,
  updateTransactionRanker
} from '@src/services';
import { AnyTransactionRanker } from '@src/models';
import { useNotifications } from '@src/hooks';

function setApplyToOrgField(ranker: AnyTransactionRanker): AnyTransactionRanker {
  if (!ranker) return ranker;

  ranker.apply_to_organization = !ranker.recording_id;
  return ranker;
}

export const useGetTransactionRankers = () => {
  const queryClient = useQueryClient();
  const invalidateRankingRules = () => queryClient.invalidateQueries('ranking-rules');

  const { data, isLoading, error } = useQuery<AnyTransactionRanker[]>('ranking-rules', () =>
    getTransactionRankers().then(rankers => rankers.map(setApplyToOrgField))
  );

  return { data, isLoading, error, invalidateRankingRules };
};

export const useGetTransactionRankerById = (rankingId: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['ranking-rule', rankingId];

  const invalidateRankingRule = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<AnyTransactionRanker | undefined>(cacheKey, () => {
    if (!rankingId) return;

    return getTransactionRankerById(rankingId).then(ranker => ranker && setApplyToOrgField(ranker));
  });

  return { data, isLoading, error, invalidateRankingRule };
};

export const useCreateTransactionRanker = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation((ranker: AnyTransactionRanker) =>
    createTransactionRanker(ranker)
      .then(response => {
        notifySuccess({
          title: 'Transaction ranker successfully added',
          timeout: 4000
        });

        queryClient.invalidateQueries('ranking-rules');

        return response;
      })
      .catch(e => {
        const status = e.response.status;

        notifyError({
          title: 'Could not save transaction ranker',
          content: status === 409 ? 'Pipeline id already exists' : 'Make sure all fields are correctly filled.'
        });

        throw e;
      })
  );

  return { mutateAsync, isLoading, error };
};

export const useUpdateTransactionRanker = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation((ranker: AnyTransactionRanker) =>
    updateTransactionRanker(ranker)
      .then(response => {
        notifySuccess({
          title: 'Transaction ranker successfully updated',
          timeout: 4000
        });

        queryClient.invalidateQueries('ranking-rules');

        return response;
      })
      .catch(e => {
        const status = e.response.status;

        notifyError({
          title: 'Could not save transaction ranker',
          content: status === 409 ? 'Pipeline id already exists' : 'Make sure all fields are correctly filled.'
        });

        throw e;
      })
  );

  return { mutateAsync, isLoading, error };
};

export const useDeleteTransactionRanker = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation((rankerId: string) =>
    deleteTransactionRanker(rankerId)
      .then(response => {
        notifySuccess({
          title: 'Transaction ranker successfully deleted',
          timeout: 4000
        });

        queryClient.invalidateQueries('ranking-rules');

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Could not delete transaction ranker'
        });

        throw e;
      })
  );

  return { mutate, isLoading, error };
};
