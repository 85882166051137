export const isEmpty = (list: any[] | null | undefined) => {
  return !list || (list && list.length === 0);
};

export const isJsonContent = (obj: any): boolean => {
  try {
    JSON.parse(obj);
    return true;
  } catch {
    return false;
  }
};

export const formatByteSize = (size?: number | null): String => {
  if (size === undefined || size === null) {
    return '';
  }
  if (size >= 1024 ** 3) {
    return (size / 1024 ** 2).toFixed(1) + ' GB';
  }
  if (size >= 1024 ** 2) {
    return (size / 1024 ** 2).toFixed(1) + ' MB';
  }
  if (size >= 1024) {
    return (size / 1024).toFixed(1) + ' KB';
  }
  return size + ' B';
};

export const formatDatetimeString = (date?: string | null): string => {
  if (date === undefined || date === null) {
    return '';
  }
  const datetime = new Date(date);
  return datetime.toLocaleString();
};
