import { useState } from 'react';

type TextPreviewerProps = {
  content: Blob;
};

export const TextPreviewer = ({ content }: TextPreviewerProps) => {
  const [txt, setTxt] = useState<string>('');
  content.text().then(setTxt);

  return (
    <div className="max-h-96 mt-2 p-2 border border-gray-300 rounded-md font-mono text-xs overflow-auto">{txt}</div>
  );
};
