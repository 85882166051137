import React, { useEffect, useRef } from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
import { SortIcon, SortUpIcon, SortDownIcon } from './Icons';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { useSearchParams } from 'react-router-dom';

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);
  const searchInput = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 75 && e.metaKey) {
      searchInput.current?.focus();
    }
  };

  window.addEventListener('keydown', event => {
    handleKeyDown(event);
  });

  return (
    <div>
      <div>
        <label htmlFor="search" className="block text-sm font-medium text-gray-700">
          Quick search
        </label>
        <div className="mt-1 relative flex items-center">
          <input
            type="text"
            name="search"
            value={value || ''}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            ref={searchInput}
            id="search"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
          />
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
              ⌘K
            </kbd>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id, render } }: any) => {
  const options = React.useMemo(() => {
    const options = new Set<string>();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  return (
    <div>
      <label htmlFor="search" className="block text-sm font-medium text-gray-700">
        {render('Header')}
      </label>
      <div className="mt-1 relative flex items-center">
        <select
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
          name={id}
          id={id}
          value={filterValue}
          onChange={e => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Any</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const Table = ({
  columns,
  data,
  enableGlobalFilter,
  enablePagination,
  saveTableState,
  rowPropsFn,
  ...rest
}: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialState = JSON.parse(atob(searchParams.get('tableState') || 'e30='));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable<{}>(
    {
      columns,
      data,
      initialState,
      ...rest
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (saveTableState) {
      const stateQs = searchParams.get('tableState');
      const newState = btoa(JSON.stringify(state));
      if (newState !== stateQs) {
        searchParams.set('tableState', newState);
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* Render Filters */}
      <div className="sm:flex sm:gap-x-2">
        {enableGlobalFilter ? (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        ) : null}

        {headerGroups.map(headerGroup =>
          headerGroup.headers.map(column =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                {/* Render Headers */}
                <thead className="bg-gray-50">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="flex items-center justify-between">
                            {column.render('Header')}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {/* Render Table */}
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {page.map((row: any, i: any) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps(rowPropsFn?.(row))}>
                        {row.cells.map((cell: any) => {
                          return (
                            <td {...cell.getCellProps()} className="px-6 py-4 whitespace-pre-wrap" role="cell">
                              {cell.column.Cell.name === 'Cell' ? (
                                cell.render('Cell')
                              ) : (
                                <div className="text-sm text-gray-500">{cell.render('Cell')}</div>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      {enablePagination && (
        <div className="md:mx-8">
          <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
              <button
                className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                onClick={() => previousPage()}
              >
                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
              </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
              <button className="border-transparent text-gray-500 pt-4 text-sm font-medium">
                Showing <b>1</b> to <b>{page.length}</b> of <b>{data.length}</b> results
              </button>
            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
              <button
                className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                onClick={() => nextPage()}
              >
                Next
                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};
