import request, { apiRequest } from '../request';
import { PageResponse, Transaction } from '@src/models';

const API_ENDPOINTS = {
  TRANSACTIONS: '/transactions'
};

export const getTransactions = async (): Promise<PageResponse<Transaction[]>> =>
  (await apiRequest(request.get(API_ENDPOINTS.TRANSACTIONS))).content;

export const getTransactionDetails = async (transactionId: string): Promise<Transaction> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.TRANSACTIONS}/${transactionId}`))).content;

export const getTransactionResponseData = async (
  transactionId: string
): Promise<{ content: Blob; headers: { [key: string]: string } }> =>
  apiRequest(request.get(`${API_ENDPOINTS.TRANSACTIONS}/${transactionId}/response_data`, { responseType: 'blob' }));

export const getTransactionRequestData = async (
  transactionId: string
): Promise<{ content: Blob; headers: { [key: string]: string } }> =>
  apiRequest(request.get(`${API_ENDPOINTS.TRANSACTIONS}/${transactionId}/request_data`, { responseType: 'blob' }));

export const transactionResponseDataReplace = async (
  transactionId: string,
  formData: FormData,
  applyToAllTransactions: boolean
): Promise<any> =>
  apiRequest(
    request.patch(
      `${API_ENDPOINTS.TRANSACTIONS}/${transactionId}/response_data?replace_all_transactions=${applyToAllTransactions}`,
      formData
    )
  );

export const transactionUpdate = async (transactionId: string, transaction: Transaction): Promise<any> =>
  apiRequest(request.patch(`${API_ENDPOINTS.TRANSACTIONS}/update/${transactionId}`, transaction));
