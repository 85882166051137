import { MenuButton } from '@src/components';
import { CompareTransactionResult } from '@src/models';
import classNames from 'classnames';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import routes from '@src/routes';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

export const TransactionValidationOutput = ({ output }: { output: CompareTransactionResult[] }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const listRef = useRef<HTMLUListElement>(null);

  const incorrectResponseIndexes = useMemo(() => {
    return output
      .map((item, index) => ({ equal: item.is_equal, index }))
      .filter(item => !item.equal)
      .map(item => item.index);
  }, [output]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedIncorrectIndex, setSelectedIncorrectIndex] = useState<number>(0);

  const scrollToItem = (index: number) => {
    if (!listRef.current) return;

    const liElement = listRef.current.children[incorrectResponseIndexes[index - 1]];
    liElement?.scrollIntoView({ behavior: 'smooth' });
  };

  const openTransactionDetails = (id: string) => {
    navigate(generatePath(routes.TRANSACTION_DETAILS, { id }), {
      state: { previousPage: location.pathname }
    });
  };

  useEffect(() => {
    scrollToItem(selectedIncorrectIndex);
  }, [selectedIncorrectIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-4 divide-y divide-gray-500 flex flex-col gap-2">
      <div className="flex items-end">
        <div className="flex-1 grid grid-cols-[auto,1fr] gap-x-3">
          <p className="font-medium">Total checked:</p>
          <p className="font-medium">{output.length}</p>
          <p className="font-medium">Incorrect responses:</p>
          <p className="font-medium">
            {selectedIncorrectIndex} / {incorrectResponseIndexes.length}
          </p>
        </div>
        <div className="flex flex-row">
          <button
            className="flex items-center text-gray-700 hover:text-blue-600 disabled:opacity-50 disabled:hover:text-gray-700 disabled:cursor-not-allowed"
            disabled={incorrectResponseIndexes.length === 0}
            onClick={() => {
              if (selectedIncorrectIndex === 1) return;
              setSelectedIncorrectIndex(selectedIncorrectIndex - 1);
            }}
          >
            <ChevronUpIcon className="w-6 h-6" />
          </button>
          <button
            className="flex items-center text-gray-700 hover:text-blue-600 disabled:opacity-50 disabled:hover:text-gray-700 disabled:cursor-not-allowed"
            disabled={incorrectResponseIndexes.length === 0}
            onClick={() => {
              if (selectedIncorrectIndex === incorrectResponseIndexes.length) return;
              setSelectedIncorrectIndex(selectedIncorrectIndex + 1);
            }}
          >
            <ChevronDownIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
      <ul ref={listRef} className="p-4 divide-y divide-gray-100 max-h-96 overflow-y-auto overflow-x-auto">
        {output.map(result => (
          <li key={result.txn_id} className="flex items-center justify-between gap-x-6 py-1">
            <div className="min-w-0">
              <div className="flex flex-row gap-x-3">
                <div className="pt-[3px] flex items-start gap-x-3">
                  <div
                    className={classNames(
                      'flex-none rounded-full p-1',
                      result.is_equal ? 'bg-emerald-500/20' : 'bg-rose-400/20'
                    )}
                  >
                    <div
                      className={classNames(
                        'h-1.5 w-1.5 rounded-full',
                        result.is_equal ? 'bg-emerald-500' : 'bg-rose-400'
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-x-2 min-w-0">
                  <div className="flex flex-row gap-x-1 overflow-hidden">
                    <p className=" text-gray-800 font-medium text-sm">{result.method}</p>
                    <p className="text-gray-800 font-medium text-sm truncate text-ellipsis" title={result.url}>
                      {result.url}
                    </p>
                  </div>
                  <div className="flex text-xs leading-5 text-gray-600">
                    <p className="whitespace-nowrap">
                      {result.content_type === 'DS_UNKNOWN' ? '/' : result.content_type}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <MenuButton
                items={[
                  {
                    name: 'Open original transaction',
                    onClick: () => openTransactionDetails(result.txn_id)
                  },
                  {
                    name: 'Open returned transaction',
                    onClick: () => result.returned_txn_id && openTransactionDetails(result.returned_txn_id)
                  },
                  {
                    hidden: result.is_equal,
                    name: 'View diff',
                    onClick: () => {
                      navigate(
                        generatePath(routes.TRANSACTION_DIFF, {
                          leftId: result.returned_txn_id,
                          rightId: result.txn_id
                        }),
                        {
                          state: { previousPage: location.pathname }
                        }
                      );
                    }
                  }
                ]}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
