import { FormEvent, useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Button, Table, Input, CommonRankerConfig } from '@src/components';
import { useNavigate, useParams } from 'react-router';
import { useCreateTransactionRanker, useGetTransactionRankerById, useUpdateTransactionRanker } from '@src/hooks';
import {
  ResponseCodeRankingRule,
  ResponseCodeTransactionRanker,
  TransactionRankerType
} from '@src/models';
import routes from '@src/routes';

const RemoveRuleButton = ({ row, removeRowClicked }: any) => (
  <Button
    small
    danger
    onClick={() => removeRowClicked(row.original as ResponseCodeTransactionRanker)}
    className="float-right"
  >
    Remove
  </Button>
);

const columns = [
  {
    id: 1,
    Header: 'Status Code',
    accessor: 'status_code'
  },
  {
    id: 2,
    Header: 'Score',
    accessor: 'score'
  },
  {
    id: 3,
    Cell: RemoveRuleButton,
    idAccessor: 'id'
  }
];

const newRuleInitialData: ResponseCodeRankingRule = {
  status_code: '200',
  score: 0
};

export const ResponseCodeRanker = ({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate();
  const { id: rankerId } = useParams() as { id: string };

  const { mutateAsync: saveNewRanker } = useCreateTransactionRanker();
  const { mutateAsync: updateRanker } = useUpdateTransactionRanker();

  const { data: rankingRule, isLoading } = useGetTransactionRankerById(rankerId);

  const [ranker, setRanker] = useState<ResponseCodeTransactionRanker>({
    id: '',
    type: TransactionRankerType.ResponseCodeRanking,
    domain_pattern: '',
    ticket_id: '',
    apply_to_organization: false,
    enabled: true,
    params: {
      rules: []
    }
  });
  const [newFieldRule, setNewFieldRule] = useState<ResponseCodeRankingRule>(newRuleInitialData);

  const validateResponseStatusCode = (value: string) => {
    return value.match(/^\d{3}$/);
  };

  const saveRanker = (e: FormEvent) => {
    e.preventDefault();
    if (ranker.params.rules.length === 0) {
      alert('Please add at least one rule.');
      return;
    }

    if (isEdit) {
      updateRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    } else {
      saveNewRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    }
  };

  const addNewRule = () => {
    if (newFieldRule.status_code === '' || ranker.params.rules.some(r => r.status_code === newFieldRule.status_code)) {
      alert('Status code should be unique and not an empty string');
      return;
    }

    const isResponseCodeValid = validateResponseStatusCode(newFieldRule.status_code);
    if (!isResponseCodeValid) {
      alert('Status code should have only three numbers');
      return;
    }

    setRanker({
      ...ranker,
      params: {
        rules: [...ranker.params.rules, newFieldRule]
      }
    });

    setNewFieldRule(newRuleInitialData);
  };

  const removeRowClicked = (rule: ResponseCodeRankingRule) => {
    setRanker({
      ...ranker,
      params: { rules: ranker.params.rules.filter(f => f.status_code !== rule.status_code) }
    });
  };

  useEffect(() => {
    if (!rankingRule) return;

    setRanker(rankingRule as ResponseCodeTransactionRanker);
  }, [rankingRule]);

  if (isLoading) return <></>;

  return (
    <form onSubmit={saveRanker}>
      <div className="space-y-6">
        <CommonRankerConfig
          title="Response Code Ranking"
          description={
            <>
              Configure ranking rules for <b>response code</b>.
            </>
          }
          ranker={ranker}
          updateRanker={ranker => setRanker(ranker as ResponseCodeTransactionRanker)}
        />

        <div className="py-4">
          <div className="w-full border-t border-gray-300"></div>
        </div>

        <div className="space-y-2">
          <div className="space-y-1">
            <div className="mt-6 flex gap-4 items-center">
              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Status Code"
                  type="text"
                  name="status_code"
                  id="status_code"
                  placeholder="200"
                  value={newFieldRule.status_code}
                  onChange={e => setNewFieldRule({ ...newFieldRule, status_code: e.target.value })}
                />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Score"
                  type="number"
                  name="score"
                  id="score"
                  placeholder="Score"
                  onFocus={e => e.target.select()}
                  value={newFieldRule.score}
                  onChange={e => setNewFieldRule({ ...newFieldRule, score: Number(e.target.value) })}
                />
              </div>

              <div className="ml-auto">
                <Button icon={<PlusIcon />} secondary onClick={addNewRule}>
                  Add Rule
                </Button>
              </div>
            </div>
            <div className="border-b border-gray-200">
              <Table columns={columns} data={ranker.params?.rules} removeRowClicked={removeRowClicked} />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            onClick={() => navigate(routes.CONFIGURATIONS_RANKING)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
