import { useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { PipelineSchemaWithConfigurationId, PipelineStage } from '@src/models';
import classNames from 'classnames';

const SearchType = {
  all: 'All',
  ...PipelineStage
};

const getFilterFunction = (type: string, search: string) => (item: PipelineSchemaWithConfigurationId) => {
  search = search.toLowerCase();

  const matchesType = type === SearchType.all || PipelineStage[item.applyTo as keyof typeof PipelineStage] === type;

  const matchesId = item.id.toLowerCase().indexOf(search) >= 0;
  const matchesSelectorsIds = item.selectors.map(s => s.id).some(id => id.toLowerCase().indexOf(search) >= 0);
  const matchesModifiersIds = item.modifiers.map(s => s.id).some(id => id.toLowerCase().indexOf(search) >= 0);
  const matchesSearch = search === '' || matchesId || matchesSelectorsIds || matchesModifiersIds;

  return matchesType && matchesSearch;
};

type DynamicContentModFilterProps = {
  onFilterChange: (f: (item: PipelineSchemaWithConfigurationId) => boolean) => void;
};

export const DynamicContentModFilter = ({ onFilterChange }: DynamicContentModFilterProps) => {
  const [search, setSearch] = useState<string>('');
  const [type, setType] = useState<string>(SearchType.all);

  const isSelectedType = (t: string) => t === type;

  useEffect(() => {
    onFilterChange(getFilterFunction(type, search));
  }, [search, type]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="py-1 px-4 sm:px-6 flex flex-col md:flex-row border-t border-b justify-between">
      <div className="flex-1 flex">
        <form className="w-full flex lg:ml-0" onSubmit={e => e.preventDefault()}>
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5" />
            </div>
            <input
              onChange={e => setSearch(e.target.value.toLowerCase() || '')}
              id="search-field"
              className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
              placeholder="Search"
              type="search"
              name="search"
            />
          </div>
        </form>
      </div>
      <div className="flex justify-between items-center md:ml-4 lg:ml-6 text-sm py-1">
        <span className="hidden md:block text-gray-600">Stage:</span>
        {Object.values(SearchType).map(type => (
          <button
            key={type}
            onClick={() => setType(type)}
            className={classNames(
              'ml-0 md:ml-2 rounded-lg px-2 py-1 focus-within:outline-none focus-within:ring-0',
              isSelectedType(type)
                ? 'text-indigo-800 bg-indigo-100'
                : 'text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50'
            )}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  );
};
