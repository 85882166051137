import { useState, useEffect } from 'react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { ConfigurationResult, FileInputButton } from '@src/components';
import { StudioImportResult, StudioTransportData } from '@src/models';
import { useImportConfiguration } from '@src/hooks';

export const ConfigurationImport = () => {
  const [file, setFile] = useState<File | null>(null);

  const [importResult, setImportResult] = useState<StudioImportResult[]>();
  const { mutate: importConfiguration } = useImportConfiguration(results => {
    setImportResult(results);
  });

  useEffect(() => {
    file?.text().then(content => {
      const importData = JSON.parse(content) as StudioTransportData;
      importConfiguration(importData);
    });
  }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col">
      <div className="rounded-md pt-7 mb-5 px-7 border border-gray-200 relative">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Import Configuration</h3>
              <p className="mt-1 text-sm text-gray-500">Import configuration from a JSON file...</p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <FileInputButton
                icon={<ArrowUpTrayIcon />}
                multiple={false}
                onChange={e => {
                  const files = e.target.files;
                  if (files && files.length > 0) {
                    setFile(files[0]);
                    setImportResult(undefined);
                  }
                }}
              >
                Import
              </FileInputButton>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center p-6">
          {importResult && <ConfigurationResult results={importResult} />}
        </div>
      </div>
    </div>
  );
};
