import { FormEvent, useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Button, Table, Input, CommonRankerConfig } from '@src/components';
import { useNavigate, useParams } from 'react-router';
import { useCreateTransactionRanker, useGetTransactionRankerById, useUpdateTransactionRanker } from '@src/hooks';
import { HeaderRankingRule, HeaderTransactionRanker, TransactionRankerType } from '@src/models';
import routes from '@src/routes';

const RemoveRuleButton = ({ row, removeRowClicked }: any) => (
  <Button
    small
    danger
    onClick={() => removeRowClicked(row.original as HeaderTransactionRanker)}
    className="float-right"
  >
    Remove
  </Button>
);

const columns = [
  {
    id: 1,
    Header: 'Header Field',
    accessor: 'header'
  },
  {
    id: 2,
    Header: 'Score',
    accessor: 'score'
  },
  {
    id: 3,
    Cell: RemoveRuleButton,
    idAccessor: 'id'
  }
];

const newRuleInitialData: HeaderRankingRule = {
  header: '',
  score: 0
};

type HeaderRankerProps = {
  isEdit?: boolean;
};

export const HeaderRanker = ({ isEdit }: HeaderRankerProps) => {
  const navigate = useNavigate();
  const { id: rankerId } = useParams() as { id: string };

  const { mutateAsync: saveNewRanker } = useCreateTransactionRanker();
  const { mutateAsync: updateRanker } = useUpdateTransactionRanker();

  const { data: rankingRule, isLoading } = useGetTransactionRankerById(rankerId);

  const [ranker, setRanker] = useState<HeaderTransactionRanker>({
    id: '',
    type: TransactionRankerType.HeaderRanking,
    domain_pattern: '',
    ticket_id: '',
    apply_to_organization: false,
    enabled: true,
    params: {
      rules: []
    }
  });

  useEffect(() => {
    if (!rankingRule) return;

    setRanker(rankingRule as HeaderTransactionRanker);
  }, [rankingRule]);

  const [newFieldRule, setNewFieldRule] = useState<HeaderRankingRule>(newRuleInitialData);

  const saveRanker = (e: FormEvent) => {
    e.preventDefault();
    if (ranker.params.rules.length === 0) {
      alert('Please add at least one rule.');
      return;
    }

    ranker.params.rules.forEach(rule => {
      if (rule.header === 'cookie') {
        alert(`Header ${rule.header} is not supported.`);
        return;
      }
    });

    if (isEdit) {
      updateRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    } else {
      saveNewRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    }
  };

  const removeRowClicked = (rule: HeaderRankingRule) => {
    setRanker({ ...ranker, params: { rules: ranker.params.rules.filter(f => f.header !== rule.header) } });
  };

  const addNewRule = () => {
    if (newFieldRule.header === '' || ranker.params.rules.some(r => r.header === newFieldRule.header)) {
      alert('Header Field should be unique and not an empty string');
      return;
    }

    setRanker({
      ...ranker,
      params: {
        rules: [...ranker.params.rules, newFieldRule]
      }
    });

    setNewFieldRule(newRuleInitialData);
  };

  if (isLoading) return <></>;

  return (
    <form onSubmit={saveRanker}>
      <div className="space-y-6">
        <CommonRankerConfig
          title="Header Ranking"
          description={
            <>
              Configure ranking rules for request <b>headers</b>.
            </>
          }
          ranker={ranker}
          updateRanker={ranker => setRanker(ranker as HeaderTransactionRanker)}
        />

        <div className="py-4">
          <div className="w-full border-t border-gray-300"></div>
        </div>

        <div className="space-y-2">
          <div className="space-y-1">
            <div className="mt-6 flex gap-4 items-center">
              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Header"
                  type="text"
                  name="header"
                  id="header"
                  placeholder="User-Agent (example)"
                  value={newFieldRule.header}
                  onChange={e => setNewFieldRule({ ...newFieldRule, header: e.target.value })}
                />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Score"
                  type="number"
                  name="score"
                  id="score"
                  placeholder="Score"
                  onFocus={e => e.target.select()}
                  value={newFieldRule.score}
                  onChange={e => setNewFieldRule({ ...newFieldRule, score: Number(e.target.value) })}
                />
              </div>

              <div className="ml-auto">
                <Button icon={<PlusIcon />} secondary onClick={addNewRule}>
                  Add Rule
                </Button>
              </div>
            </div>
            <div className="border-b border-gray-200">
              <Table columns={columns} data={ranker.params?.rules} removeRowClicked={removeRowClicked} />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            onClick={() => navigate(routes.CONFIGURATIONS_RANKING)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
