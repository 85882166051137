import './style.css';
import classNames from 'classnames';

export type LoadingProps = {
  title?: string;
  className?: string;
};

export const Loading = ({ title, className }: LoadingProps) => {
  return (
    <div className={classNames('flex flex-col items-center justify-center h-full overflow-hidden', className)}>
      <div className="pl-loader"></div>
      {title && <div>{title}</div>}
    </div>
  );
};
