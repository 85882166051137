import { BoltIcon } from '@heroicons/react/24/solid';
import { useGetRecordingDetails } from '@src/hooks';
import { BackgroundJobType, DelteckMaconomyChartStateJob } from '@src/models';
import { useEffect } from 'react';

type  DelteckMaconomyChartStateProps = {
  jobModel: DelteckMaconomyChartStateJob;
  setJobModel: (job: DelteckMaconomyChartStateJob) => void;
};

export const  DelteckMaconomyChartStateModal = ({ setJobModel }: DelteckMaconomyChartStateProps) => {
  const { data: recordingDetails } = useGetRecordingDetails();

  useEffect(() => {
    if (recordingDetails) {
      setJobModel({
        type: BackgroundJobType.DelteckMaconomyChartState,
        params: {
          recording_id: recordingDetails.recording_id
        }
      });
    }
  }, [recordingDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="group flex cursor-default select-none pb-6 p-3">
        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
          <BoltIcon className="h-6 w-6 text-white" />
        </div>
        <div className="ml-4 flex-auto">
          <p className="text-sm font-medium text-gray-700">Delteck Maconomy Chart State</p>
          <p className="text-sm text-gray-500">Delteck Maconomy Chart State</p>
        </div>
      </div>
    </>
  );
};
