export enum BackgroundJobType {
  PipelineMigration = 'PipelineMigration',
  TransactionConflict = 'TransactionConflict',
  ElasticCloneExport = 'ElasticCloneExport',
  SuggestSearchKeys = 'SuggestSearchKeys',
  RequestHeadersCompare = 'RequestHeadersCompare',
  ProcessCloneHostnames = 'ProcessCloneHostnames',
  DisableDuplicatedAssets = 'DisableDuplicatedAssets',
  TransactionValidation = 'TransactionValidation',
  DelteckMaconomyChartState= 'DelteckMaconomyChartState'
}

export const BackgroundJobLabels: { [key: string]: { label: string; color: string } } = {
  [BackgroundJobType.PipelineMigration]: {
    label: 'Pipeline Migration',
    color: 'blue'
  },
  [BackgroundJobType.TransactionConflict]: {
    label: 'Workflows Analyzer',
    color: 'green'
  },
  [BackgroundJobType.ElasticCloneExport]: {
    label: 'Export Clone to Elastic',
    color: 'pink'
  },
  [BackgroundJobType.SuggestSearchKeys]: {
    label: 'Suggest Search Keys',
    color: 'purple'
  },
  [BackgroundJobType.RequestHeadersCompare]: {
    label: 'Request Headers Compare',
    color: 'red'
  },
  [BackgroundJobType.ProcessCloneHostnames]: {
    label: 'Process Clone Hostnames',
    color: 'orange'
  },
  [BackgroundJobType.DisableDuplicatedAssets]: {
    label: 'Disable Duplicated Assets',
    color: 'indigo'
  },
  [BackgroundJobType.DelteckMaconomyChartState]: {
    label: 'Delteck Maconomy Chart State',
    color: 'cyan'
  },
  [BackgroundJobType.TransactionValidation]: {
    label: 'Transaction Validation',
    color: 'fuchsia'
  }
};

enum BackgroundJobStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR'
}

export type BackgroundJob<T extends BackgroundJobType, D, O = any> = {
  type: T;
  params: D;
  job_id?: string;
  status?: BackgroundJobStatus;
  error?: any;
  output?: O;
  message?: string;
};

type BackgroundJobParamsBase = {
  recording_id: string;
};

export type PipelineMigrationJobParams = BackgroundJobParamsBase & {
  pipeline_id: string;
  since?: number;
  until?: number;
};
export type TransactionConflictJobParams = BackgroundJobParamsBase & {};
export type ElasticCloneExportJobParams = BackgroundJobParamsBase & {};
export type SuggestSearchKeysJobParams = BackgroundJobParamsBase & {};
export type RequestHeadersCompareJobParams = BackgroundJobParamsBase & {};
export type ProcessCloneHostnamesJobParams = BackgroundJobParamsBase & {};
export type DisableDuplicatedAssetsJobParams = BackgroundJobParamsBase & {};
export type DelteckMaconomyChartStateJobParams = BackgroundJobParamsBase & {};
export type TransactionValidationJobParams = BackgroundJobParamsBase & {};

export type PipelineMigrationJob = BackgroundJob<BackgroundJobType.PipelineMigration, PipelineMigrationJobParams>;
export type TransactionConflictJob = BackgroundJob<BackgroundJobType.TransactionConflict, TransactionConflictJobParams>;
export type ElasticCloneExportJob = BackgroundJob<BackgroundJobType.ElasticCloneExport, ElasticCloneExportJobParams>;
export type SuggestSearchKeysJob = BackgroundJob<BackgroundJobType.SuggestSearchKeys, SuggestSearchKeysJobParams>;
export type RequestHeadersCompareJob = BackgroundJob<
  BackgroundJobType.RequestHeadersCompare,
  RequestHeadersCompareJobParams
>;
export type ProcessCloneHostnamesJob = BackgroundJob<
  BackgroundJobType.ProcessCloneHostnames,
  ProcessCloneHostnamesJobParams
>;
export type DisableDuplicatedAssetsJob = BackgroundJob<
  BackgroundJobType.DisableDuplicatedAssets,
  DisableDuplicatedAssetsJobParams
>;
export type DelteckMaconomyChartStateJob = BackgroundJob<
  BackgroundJobType.DelteckMaconomyChartState,
  DelteckMaconomyChartStateJobParams
>;

export type CompareTransactionResult = {
  method: string;
  url: string;
  txn_id: string;
  is_equal: boolean;
  returned_txn_id: string | undefined;
  content_type: string | undefined;
};
export type TransactionValidationJob = BackgroundJob<
  BackgroundJobType.TransactionValidation,
  TransactionValidationJobParams,
  CompareTransactionResult[]
>;

export type AnyBackgroundJob =
  | PipelineMigrationJob
  | TransactionConflictJob
  | ElasticCloneExportJob
  | SuggestSearchKeysJob
  | RequestHeadersCompareJob
  | ProcessCloneHostnamesJob
  | DisableDuplicatedAssetsJob
  | DelteckMaconomyChartStateJob
  | TransactionValidationJob;
