import classNames from 'classnames';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
  action?: React.ReactNode;
};

export const Input = ({ label, className, action, ...inputProps }: InputProps) => {
  const name = label?.toLocaleLowerCase().replace(/\s/g, '-');

  return (
    <div className="w-full">
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className={classNames('flex', label && 'mt-1')}>
        <input
          {...inputProps}
          type={inputProps.type || 'text'}
          name={name}
          id={`id-${name}`}
          className={classNames(
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
            className
          )}
        />
        {action}
      </div>
    </div>
  );
};

export const TicketIdInput = ({ value, onChange }: any) => {
  const validate = (value: string) => {
    const regexPattern = /^[a-zA-Z]{2,}-\d+$/; // Example regex pattern: "ABC-123"
    return value && regexPattern.test(value);
  };

  return (
    <div className="flex-column">
      <label className="block text-sm font-medium text-gray-700">Jira Ticket</label>
      <div className="flex mt-1">
        <input
          name="ticket-id"
          className="w-full py-2 pr-10 text-gray-700 bg-white border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          type="text"
          id="ticket-id"
          placeholder="RND-1234"
          value={value}
          onChange={onChange}
        />
        {validate(value) ? (
          <a
            href={`https://demostackdev.atlassian.net/browse/${value}`}
            target="_blank"
            rel="noreferrer"
            className="px-2 flex items-center cursor-pointer hover:text-indigo-700 gap-1"
          >
            <ArrowTopRightOnSquareIcon className="w-5 h-5" />
          </a>
        ) : null}
      </div>
    </div>
  );
};
