import { PageResponse } from '@src/models';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CacheEntry, CacheStatistics, InvalidateCacheParams } from '@src/models/cache';
import { invalidateCache, getCache, getCacheStatistics } from '@src/services/api/cache';
import { useNotifications } from './notification';

export const useGetCacheStatistics = () => {
  const queryClient = useQueryClient();
  const invalidateCacheStatistics = () => queryClient.invalidateQueries('cache-statistics');
  const { data, isLoading, error } = useQuery<CacheStatistics>('cache-statistics', () => getCacheStatistics());

  return { data, isLoading, error, invalidateCacheStatistics };
};

export const useGetCache = () => {
  const queryClient = useQueryClient();
  const invalidateCache = () => queryClient.invalidateQueries('cache');
  const { data, isLoading, error } = useQuery<PageResponse<CacheEntry[]>>('cache', getCache);

  return { data, isLoading, error, invalidateCache };
};

export const useInvalidateCache = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation((invalidateCacheParams: InvalidateCacheParams) =>
    invalidateCache(invalidateCacheParams)
      .then(response => {
        notifySuccess({
          title: 'Cache was successfully invalidated',
          timeout: 4000
        });

        queryClient.invalidateQueries('cache-clear');

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Could not invalidate cache'
        });

        throw e;
      })
  );

  return { mutateAsync, isLoading, error };
};
