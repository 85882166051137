import { BackgroundJobsList } from '@src/components';

export const BackgroundJobsPage = () => {
  return (
    <>
      <div className="py-6">
        <BackgroundJobsList />
      </div>
    </>
  );
};
