import { PipelineSchemaWithConfigurationId, PipelineStage } from '@src/models';
import { DynamicContentModListItem } from './DynamicContentModListItem';

type DynamicContentModListProps = {
  pipelines: PipelineSchemaWithConfigurationId[];
  onItemClick: (item: PipelineSchemaWithConfigurationId) => void;
};

export const DynamicContentModList = ({ pipelines, onItemClick }: DynamicContentModListProps) => {
  return (
    <nav className="relative overflow-y-auto" aria-label="Directory">
      <>
        {Object.entries(PipelineStage).map(([stageKey, stageLabel]) => {
          const pipelinesByStage = pipelines
            .filter(p => p.applyTo === stageKey)
            .sort((p1, p2) => (p1.priority || 0) - (p2.priority || 0));

          return (
            <div key={`avbl-type-${stageKey.toLocaleLowerCase()}`}>
              <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-4 sm:px-6 py-1 text-sm font-medium text-gray-500">
                <h3>{stageLabel}</h3>
              </div>
              <ul className="relative divide-y divide-gray-200">
                {pipelinesByStage.length > 0 ? (
                  pipelinesByStage.map((item, index) => (
                    <DynamicContentModListItem
                      key={`dynamic-pipeline-list-item-${index}`}
                      index={index}
                      item={item}
                      onItemClick={() => onItemClick(item)}
                    />
                  ))
                ) : (
                  <div className="w-full flex justify-center text-sm px-4 py-4 sm:px-6">
                    This stage has no pipelines
                  </div>
                )}
              </ul>
            </div>
          );
        })}
      </>
    </nav>
  );
};
