import {
  Button,
  DateDistanceRepresentation,
  JsonRepresentation,
  MenuButton,
  Table,
  renderBackgroundJobStatus
} from '@src/components';
import { BoltIcon } from '@heroicons/react/24/outline';
import { useGetBackgroundJobs } from '@src/hooks';
import { isEmpty } from '@src/utils';
import { AnyBackgroundJob, BackgroundJobLabels, BackgroundJobType } from '@src/models';
import { BackgroundJobModal } from './jobs';
import { useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router';
import routes from '@src/routes';

const JobTypeCell = () => {
  // Memoized
  const jobComponents = Object.fromEntries(
    Object.entries(BackgroundJobLabels).map(([jobType, jobLabelInfo]) => {
      return [
        jobType,
        <span
          className={`inline-flex items-center text-center whitespace-pre px-2.5 py-0.5 rounded-full text-xs font-medium bg-${jobLabelInfo.color}-100 text-${jobLabelInfo.color}-800`}
        >
          {jobLabelInfo.label}
        </span>
      ];
    })
  );

  const jobTypeCell = ({ cell }: any) => {
    return jobComponents[cell.value as BackgroundJobType];
  };

  return jobTypeCell;
};

const StatusCell = ({ cell }: any) => {
  return renderBackgroundJobStatus(cell.value as string);
};

const MessageCell = ({ row }: any) => {
  const jobData = row.original as AnyBackgroundJob;
  return <>{jobData.error || jobData.message}</>;
};

const DetailsButton = ({ row }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      small
      secondary
      onClick={() => {
        navigate(generatePath(routes.BACKGROUND_JOB_DETAILS, { id: row.original.job_id }), {
          state: { previousPage: location.pathname }
        });
      }}
    >
      Details
    </Button>
  );
};

const columns = [
  {
    id: 1,
    Header: 'Type',
    accessor: 'type',
    Cell: JobTypeCell()
  },
  {
    id: 2,
    Header: 'Params',
    accessor: 'params',
    Cell: JsonRepresentation
  },
  {
    id: 3,
    Header: 'Status',
    accessor: 'status',
    Cell: StatusCell
  },
  {
    id: 4,
    Header: 'Message',
    Cell: MessageCell
  },
  {
    id: 5,
    Header: 'Created at',
    accessor: 'created_at',
    Cell: DateDistanceRepresentation
  },
  {
    id: 6,
    Header: 'Output',
    accessor: 'output',
    Cell: DetailsButton
  }
];

export const BackgroundJobsList = () => {
  const { data: allBackgroundJobs, isLoading } = useGetBackgroundJobs();

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {isEmpty(allBackgroundJobs) ? (
        <BackgroundJobEmpty />
      ) : (
        <>
          <BackgroundJobsHeader />
          <Table columns={columns} data={allBackgroundJobs} />
        </>
      )}
    </>
  );
};

const RunJobButton = () => {
  const [jobType, setJobType] = useState<BackgroundJobType | null>(null);

  return (
    <>
      <MenuButton
        title="Select a job to run"
        items={Object.entries(BackgroundJobLabels).map(([jobType, rankerLabelInfo]) => ({
          name: rankerLabelInfo.label,
          onClick: () => {
            setJobType(jobType as BackgroundJobType);
          }
        }))}
      />

      {jobType && <BackgroundJobModal show={!!jobType} closeModal={() => setJobType(null)} jobType={jobType} />}
    </>
  );
};

const BackgroundJobsHeader = () => (
  <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
    <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
      <div className="ml-4 mt-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Background Jobs</h3>
      </div>
      <div className="ml-4 mt-2 flex-shrink-0">
        <div className="flex items-center gap-4">
          <RunJobButton />
        </div>
      </div>
    </div>
  </div>
);

const BackgroundJobEmpty = () => {
  return (
    <div className="h-72 flex flex-col justify-center items-center">
      <BoltIcon className="w-6 h-6" />
      <h3 className="mt-2 text-md font-medium text-gray-900">No background jobs</h3>
      <div className="flex flex-col items-center gap-4 mt-6">
        <RunJobButton />
      </div>
    </div>
  );
};
