import request, { apiRequest } from '../request';
import { PostProcessExecutionReport } from '@src/models';

const API_ENDPOINTS = {
  POST_PROCESS: '/post-process',
  CREATE_RANKING_RULES: '/post-process/ranking-rules'
};

export const getPostProcessingReport = async (): Promise<PostProcessExecutionReport | null> =>
  (await apiRequest(request.get(API_ENDPOINTS.POST_PROCESS))).content;

export const startPostProcessing = async (override: boolean = false): Promise<void> =>
  (await apiRequest(request.post(`${API_ENDPOINTS.POST_PROCESS}?override=${override}`))).content;

export const createSequentialStateRules = async (): Promise<void> =>
  (await apiRequest(request.post(API_ENDPOINTS.CREATE_RANKING_RULES))).content;
