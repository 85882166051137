import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getEditorRuleById, getEditorRules, createEditorRule, deleteEditorRule, updateEditorRule } from '@src/services';
import { AnyEditorRule } from '@src/models';
import { useNotifications } from '@src/hooks';

export const useGetEditorRules = () => {
  const queryClient = useQueryClient();
  const invalidateEditorRules = () => queryClient.invalidateQueries('editor-rules');

  const { data, isLoading, error } = useQuery<AnyEditorRule[]>('editor-rules', () => getEditorRules());

  return { data, isLoading, error, invalidateEditorRules };
};

export const useGetEditorRuleById = (ruleId: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['editor-rule', ruleId];

  const invalidateEditorRules = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<AnyEditorRule | undefined>(cacheKey, () => {
    if (!ruleId) return;

    return getEditorRuleById(ruleId);
  });

  return { data, isLoading, error, invalidateEditorRules };
};

export const useCreateEditorRule = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation((editorRule: AnyEditorRule) =>
    createEditorRule(editorRule)
      .then(response => {
        notifySuccess({
          title: 'Editor rule successfully added',
          timeout: 4000
        });

        queryClient.invalidateQueries('editor-rules');

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Could not save editor rule',
          content: 'Make sure all fields are correctly filled.'
        });

        throw e;
      })
  );

  return { mutate, isLoading, error };
};

export const useUpdateEditorRule = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation((editorRule: AnyEditorRule) =>
    updateEditorRule(editorRule)
      .then(response => {
        notifySuccess({
          title: 'Editor rule successfully updated',
          timeout: 4000
        });

        queryClient.invalidateQueries('editor-rules');

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Could not update editor rule',
          content: 'Make sure all fields are correctly filled.'
        });

        throw e;
      })
  );

  return { mutate, isLoading, error };
};

export const useDeleteEditorRule = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation((ruleId: string) =>
    deleteEditorRule(ruleId)
      .then(response => {
        notifySuccess({
          title: 'Editor rule successfully deleted',
          timeout: 4000
        });

        queryClient.invalidateQueries('editor-rules');

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Could not delete editor rule'
        });

        throw e;
      })
  );

  return { mutate, isLoading, error };
};
