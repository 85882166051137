import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CommandPalette, CommandPaletteItem } from '@src/components';
import { DynamicPipelineSchemaForm } from './DynamicPipelineSchemaForm';
import { AppliedModifierOrSelector } from '@src/models';

type DynamicPipelineModalItem = CommandPaletteItem & {
  modelName: string;
};

type DynamicPipelineModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  items: DynamicPipelineModalItem[];
  onClose: () => void;
  onSubmit: (data: AppliedModifierOrSelector) => void;
  modifierOrSelector?: AppliedModifierOrSelector;
};

export const DynamicPipelineModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  items,
  modifierOrSelector
}: DynamicPipelineModalProps) => {
  const [selectedItem, setSelectedItem] = useState<DynamicPipelineModalItem | undefined>();

  useEffect(() => {
    setSelectedItem(items.find(i => i.id === modifierOrSelector?.id));
  }, [modifierOrSelector]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
      afterLeave={() =>
        setTimeout(() => {
          setSelectedItem(undefined);
        }, 400)
      }
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              {selectedItem ? (
                <DynamicPipelineSchemaForm
                  model={selectedItem.modelName}
                  onCancel={() => (modifierOrSelector ? onClose() : setSelectedItem(undefined))}
                  onSubmit={onSubmit}
                  modifierOrSelector={modifierOrSelector}
                />
              ) : (
                <CommandPalette items={items} onItemClick={item => setSelectedItem(item as DynamicPipelineModalItem)} />
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
