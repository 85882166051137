import { useGetTransactionResponseData } from '@src/hooks';
import { useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { useParams } from 'react-router';

export const TransactionValidationDiffPage = () => {
  const [texts, setTexts] = useState<[string, string]>(['', '']);

  const { leftId, rightId } = useParams<{ leftId: string; rightId: string }>() as { leftId: string; rightId: string };

  const { data: returnedTransactionData } = useGetTransactionResponseData(leftId);
  const { data: originalTransactionData } = useGetTransactionResponseData(rightId);

  if (returnedTransactionData === undefined || originalTransactionData === undefined) {
    return null;
  }

  const getTexts = async (returnedTransactionData: Blob, originalTransactionData: Blob): Promise<[string, string]> => {
    return Promise.resolve([await returnedTransactionData.text(), await originalTransactionData.text()]);
  };

  getTexts(returnedTransactionData.content, originalTransactionData.content).then(([returned, original]) => {
    setTexts([returned, original]);
  });

  return (
    <ReactDiffViewer
      leftTitle="Returned transaction"
      rightTitle="Original transaction"
      oldValue={texts[0]}
      newValue={texts[1]}
      splitView={true}
      compareMethod={DiffMethod.WORDS}
      extraLinesSurroundingDiff={20}
      styles={{ diffContainer: { fontSize: 12, fontWeight: 'lighter' }, line: { wordBreak: 'break-word' } }}
    />
  );
};
