import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createBackgroundJob, getBackgroundJobById } from '@src/services';
import { AnyBackgroundJob } from '@src/models';
import { useNotifications } from '@src/hooks';
import { getBackgroundJobs } from '@src/services';

export const useGetBackgroundJobs = () => {
  const queryClient = useQueryClient();
  const invalidateBackgroundJobs = () => queryClient.invalidateQueries('background-jobs');

  const { data, isLoading, error } = useQuery<AnyBackgroundJob[]>('background-jobs', getBackgroundJobs, {
    refetchInterval: (data?: AnyBackgroundJob[]) => (data?.some(task => task.status === 'IN_PROGRESS') ? 5000 : false)
  });

  return { data, isLoading, error, invalidateBackgroundJobs };
};

export const useGetBackgroundJobById = (jobId: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['background-job', jobId];

  const invalidateBackgroundJob = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<AnyBackgroundJob | undefined>(
    cacheKey,
    () => {
      return getBackgroundJobById(jobId);
    },
    {
      refetchInterval: (job?: AnyBackgroundJob) => (job?.status === 'IN_PROGRESS' ? 2000 : false)
    }
  );

  return { data, isLoading, error, invalidateBackgroundJob };
};

export const useCreateBackgroundJob = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation((job: AnyBackgroundJob) =>
    createBackgroundJob(job)
      .then(response => {
        notifySuccess({
          title: 'Run',
          timeout: 4000
        });

        queryClient.invalidateQueries('background-jobs');

        return response;
      })
      .catch(exception => {
        const statusCode = exception.response.status;

        notifyError({
          title: statusCode === 409 ? 'Job is already running.' : 'Unknown error.'
        });

        throw exception;
      })
      .finally(() => queryClient.invalidateQueries('background-jobs'))
  );

  return { mutateAsync, isLoading, error };
};
