import { PageResponse, Transaction } from '@src/models';
import {
  getTransactionDetails,
  getTransactions,
  getTransactionResponseData,
  getTransactionRequestData,
  transactionResponseDataReplace,
  transactionUpdate
} from '@src/services/api/transaction';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNotifications } from '@src/hooks';

export const useGetTransactions = () => {
  const queryClient = useQueryClient();
  const invalidateTransactions = () => queryClient.invalidateQueries('transactions');

  const { data, isLoading, error } = useQuery<PageResponse<Transaction[]>>('transactions', getTransactions);

  return { data, isLoading, error, invalidateTransactions };
};

export const useGetTransactionDetails = (transactionId: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['transaction-details', transactionId];

  const invalidateTransactionDetails = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<Transaction>(cacheKey, () => getTransactionDetails(transactionId));

  return { data, isLoading, error, invalidateTransactionDetails };
};

export const useGetTransactionResponseData = (transactionId: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['transaction-response-data', transactionId];

  const invalidateTransactionResponseData = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<{ content: Blob; headers: { [key: string]: string } }>(cacheKey, () =>
    getTransactionResponseData(transactionId)
  );

  return { data, isLoading, error, invalidateTransactionResponseData };
};

export const useGetTransactionRequestData = (transactionId: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['transaction-request-data', transactionId];

  const invalidateTransactionRequestData = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<{ content: Blob; headers: { [key: string]: string } }>(cacheKey, () =>
    getTransactionRequestData(transactionId)
  );

  return { data, isLoading, error, invalidateTransactionRequestData };
};

export const useResponseDataReplace = (transactionId: string) => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ({ formData, applyToAllTransactions }: { formData: FormData; applyToAllTransactions: boolean }) =>
      transactionResponseDataReplace(transactionId, formData, applyToAllTransactions)
        .then(response => {
          notifySuccess({
            title: 'Response data successfully replaced',
            timeout: 4000
          });

          queryClient.invalidateQueries('transaction-response-data');

          return response;
        })
        .catch(e => {
          notifyError({
            title: 'Could not replace response data'
          });

          throw e;
        })
  );

  return { mutate, isLoading, error };
};

export const useUpdateTransactionEnabled = (transactionId: string) => {
  const { notifySuccess, notifyError } = useNotifications();

  const { mutate, isLoading, error } = useMutation(({ transaction }: { transaction: Transaction }) =>
    transactionUpdate(transactionId, transaction)
      .then(response => {
        const transactionResponse: Transaction = response.content;
        const successNotificationTitle: string = `The transaction was ${
          transactionResponse.is_disabled ? 'disabled' : 'enabled'
        }`;

        notifySuccess({
          title: successNotificationTitle,
          timeout: 4000
        });
        return response;
      })
      .catch(exception => {
        const errorNotificationTitle: string = `Couldn't ${
          transaction.is_disabled ? 'disable' : 'enable'
        } transaction status`;

        notifyError({
          title: errorNotificationTitle
        });
        transaction.is_disabled = !transaction.is_disabled;
        throw exception;
      })
  );

  return { mutate, isLoading, error };
};

export const useUpdateTransactionPreload = (transactionId: string) => {
  const { notifySuccess, notifyError } = useNotifications();

  const { mutate, isLoading, error } = useMutation(({ transaction }: { transaction: Transaction }) =>
    transactionUpdate(transactionId, transaction)
      .then(response => {
        const transactionResponse: Transaction = response.content;
        const successNotificationTitle: string = `The transaction preload was ${
          transactionResponse.preload ? 'enabled' : 'disabled'
        }`;

        notifySuccess({
          title: successNotificationTitle,
          timeout: 4000
        });
        return response;
      })
      .catch(exception => {
        const errorNotificationTitle: string = `Couldn't ${
          transaction.preload ? 'enable' : 'disable'
        } transaction preload`;

        notifyError({
          title: errorNotificationTitle
        });
        transaction.preload = !transaction.preload;
        throw exception;
      })
  );

  return { mutate, isLoading, error };
};
