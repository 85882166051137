import request, { apiRequest } from '../request';
import { RecordingStatistics, RecordingDetails } from '@src/models/recording';

const API_ENDPOINTS = {
  PREFIX: '/recording',
  STATISTICS: '/statistics',
  DETAILS: '/details'
};

export const getRecordingStatistics = async (): Promise<RecordingStatistics> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.PREFIX}${API_ENDPOINTS.STATISTICS}`))).content;

export const getRecordingDetails = async (): Promise<RecordingDetails> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.PREFIX}${API_ENDPOINTS.DETAILS}`))).content;
