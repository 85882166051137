import { PageResponse, RankedTransaction } from '@src/models';
import { getRankedTransactions } from '@src/services/api';
import { useQuery, useQueryClient } from 'react-query';

const sortByScoreAndId = (res: PageResponse<RankedTransaction[]>) => ({
  ...res,
  data: res.data.sort((a, b) => b.score - a.score || b.id.localeCompare(a.id))
});

export const useGetRankedTransactions = (requestId: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['audits-ranked-transactions', requestId];

  const invalidateRankedTransactions = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<PageResponse<RankedTransaction[]> | undefined>(cacheKey, () => {
    if (!requestId) return;
    return getRankedTransactions(requestId).then(sortByScoreAndId);
  });

  return { data, isLoading, error, invalidateRankedTransactions };
};
