import { FormEvent, useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { CheckOrCrossIcon, Button, Table, Input, CommonRankerConfig } from '@src/components';
import { useNavigate, useParams } from 'react-router';
import { useCreateTransactionRanker, useGetTransactionRankerById, useUpdateTransactionRanker } from '@src/hooks';
import { JsonRankingRule, JsonTransactionRanker, TransactionRankerType } from '@src/models';
import classNames from 'classnames';
import routes from '@src/routes';

const RemoveRuleButton = ({ row, removeRowClicked }: any) => (
  <Button small danger onClick={() => removeRowClicked(row.original as JsonTransactionRanker)} className="float-right">
    Remove
  </Button>
);

const columns = [
  {
    id: 1,
    Header: 'JSONPATH',
    accessor: 'path'
  },
  {
    id: 2,
    Header: 'Score',
    accessor: 'score'
  },
  {
    id: 3,
    Header: 'Strict list order',
    accessor: 'strict_list_order',
    Cell: CheckOrCrossIcon
  },
  {
    id: 4,
    Header: 'Lightweight Search',
    accessor: 'use_lightweight_search',
    Cell: CheckOrCrossIcon
  },
  {
    id: 5,
    Cell: RemoveRuleButton,
    idAccessor: 'id'
  }
];

const newRuleInitialData: JsonRankingRule = {
  path: '',
  score: 0,
  strict_list_order: false,
  use_lightweight_search: false
};

type JsonBodyRankerProps = {
  isEdit?: boolean;
};

export const JsonBodyRanker = ({ isEdit }: JsonBodyRankerProps) => {
  const navigate = useNavigate();
  const { id: rankerId } = useParams() as { id: string };

  const { mutateAsync: saveNewRanker } = useCreateTransactionRanker();
  const { mutateAsync: updateRanker } = useUpdateTransactionRanker();

  const { data: rankingRule, isLoading } = useGetTransactionRankerById(rankerId);

  const [ranker, setRanker] = useState<JsonTransactionRanker>({
    id: '',
    type: TransactionRankerType.JsonRanking,
    domain_pattern: '',
    ticket_id: '',
    apply_to_organization: false,
    enabled: true,
    params: {
      rules: []
    }
  });

  useEffect(() => {
    if (!rankingRule) return;

    setRanker(rankingRule as JsonTransactionRanker);
  }, [rankingRule]);

  const [newFieldRule, setNewFieldRule] = useState<JsonRankingRule>(newRuleInitialData);

  const saveRanker = (e: FormEvent) => {
    e.preventDefault();

    if (ranker.params.rules.length === 0) {
      alert('Please add at least one rule.');
      return;
    }

    if (isEdit) {
      updateRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    } else {
      saveNewRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    }
  };

  const removeRowClicked = (rule: JsonRankingRule) => {
    setRanker({ ...ranker, params: { rules: ranker.params.rules.filter(f => f.path !== rule.path) } });
  };

  const addNewRule = () => {
    if (newFieldRule.path === '' || ranker.params.rules.some(r => r.path === newFieldRule.path)) {
      alert('Json path should be unique and not an empty string');
      return;
    }

    setRanker({
      ...ranker,
      params: {
        rules: [...ranker.params.rules, newFieldRule]
      }
    });

    setNewFieldRule(newRuleInitialData);
  };

  if (isLoading) return <></>;

  return (
    <form onSubmit={saveRanker}>
      <div className="space-y-6">
        <CommonRankerConfig
          title="JSON Ranking"
          description={
            <>
              Configure ranking rules for <i>application/json</i> request content.
            </>
          }
          ranker={ranker}
          updateRanker={ranker => setRanker(ranker as JsonTransactionRanker)}
        />

        <div className="py-4">
          <div className="w-full border-t border-gray-300"></div>
        </div>

        <div className="space-y-2">
          <div className="space-y-1">
            <div className="mt-6 flex gap-4 items-center">
              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="JSON Path"
                  type="text"
                  name="jsonpath"
                  id="jsonpath"
                  placeholder="example.data"
                  value={newFieldRule.path}
                  onChange={e => setNewFieldRule({ ...newFieldRule, path: e.target.value })}
                />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Score"
                  type="number"
                  name="score"
                  id="score"
                  placeholder="Score"
                  onFocus={e => e.target.select()}
                  value={newFieldRule.score}
                  onChange={e => setNewFieldRule({ ...newFieldRule, score: Number(e.target.value) })}
                />
              </div>

              <Switch.Group as="div">
                <Switch.Label className="block text-sm font-medium text-gray-700 -mt-1" passive>
                  Ignore Order
                </Switch.Label>
                <dd className="pt-2 text-sm text-gray-900 sm:mt-1">
                  <Switch
                    checked={newFieldRule.strict_list_order}
                    onChange={(checked: boolean) => setNewFieldRule({ ...newFieldRule, strict_list_order: checked })}
                    className={classNames(
                      newFieldRule.strict_list_order ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        newFieldRule.strict_list_order ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </dd>
              </Switch.Group>

              <Switch.Group as="div">
                <Switch.Label className="group relative block text-sm font-medium text-gray-700 -mt-1" passive>
                  <span
                    className="w-64 group-hover:opacity-80 transition-opacity bg-indigo-700 text-xs text-white rounded-md absolute left-1/2 -top-14
    -translate-x-1/2 opacity-0 p-1 m-4 mx-auto z-50"
                    role="tooltip"
                  >
                    Please use lightweight search only on 1-level nested fields
                  </span>
                  Lightweight Search ⚠️
                </Switch.Label>
                <dd className="pt-2 text-sm text-gray-900 sm:mt-1">
                  <Switch
                    checked={newFieldRule.use_lightweight_search}
                    onChange={(checked: boolean) =>
                      setNewFieldRule({ ...newFieldRule, use_lightweight_search: checked })
                    }
                    className={classNames(
                      newFieldRule.use_lightweight_search ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        newFieldRule.use_lightweight_search ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </dd>
              </Switch.Group>

              <div className="ml-auto">
                <Button icon={<PlusIcon />} secondary onClick={addNewRule}>
                  Add Rule
                </Button>
              </div>
            </div>
            <div className="border-b border-gray-200">
              <Table columns={columns} data={ranker.params?.rules} removeRowClicked={removeRowClicked} />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            onClick={() => navigate(routes.CONFIGURATIONS_RANKING)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
