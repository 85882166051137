import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, ResponseType } from 'axios';
import { BASE_API_ENDPOINT, STUDIO_REDIRECT_URL } from '@src/env';

const defaultConfig = {
  withCredentials: true,
  baseURL: BASE_API_ENDPOINT,
  responseType: 'json' as ResponseType
};

export const attachHeaders = (instance: AxiosInstance, headers: AxiosRequestHeaders) => {
  Object.keys(headers).forEach((key: string) => {
    // @ts-ignore
    instance.defaults.headers[key] = headers[key];
  });
};

const request = (config: AxiosRequestConfig = defaultConfig): AxiosInstance => {
  const instance = axios.create(config);

  instance.interceptors.response.use(
    response => response,
    error => {
      const statusCode = error.response.status;
      if ([401, 403].indexOf(statusCode) >= 0) {
        window.location.href = STUDIO_REDIRECT_URL;
      }
      return Promise.reject(error);
    }
  );

  attachHeaders(instance, {
    Accept: 'application/json'
  });

  return instance;
};

export const apiRequest = async (request: Promise<any>) => {
  const { data, headers } = await request;
  return { content: data, headers };
};

export default request();
