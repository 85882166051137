import { Switch } from '@headlessui/react';
import { Input, TicketIdInput } from '@src/components';
import { AnyTransactionRanker } from '@src/models';
import classNames from 'classnames';
import { ReactNode } from 'react';

type CommonRankerConfigProps = {
  title: ReactNode;
  description: ReactNode;
  ranker: AnyTransactionRanker;
  updateRanker: (ranker: AnyTransactionRanker) => void;
};

export const CommonRankerConfig = ({ title, description, ranker, updateRanker }: CommonRankerConfigProps) => {
  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-lg leading-6 font-medium text-gray-900">{title}</h1>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
        <Switch.Group as="div">
          <Switch.Label className="block text-sm font-medium text-gray-700 -mt-1" passive>
            Enabled
          </Switch.Label>
          <dd className="pt-2 text-sm text-gray-900 sm:mt-1 float-right">
            <Switch
              checked={ranker.enabled || false}
              onChange={(checked: boolean) => updateRanker({ ...ranker, enabled: checked })}
              className={classNames(
                ranker.enabled ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  ranker.enabled ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-4 w-3/4 md:w-1/2">
          <TicketIdInput
            value={ranker.ticket_id}
            onChange={(e: any) => updateRanker({ ...ranker, ticket_id: e.target.value })}
          />
          <Input
            label="URL Pattern"
            type="text"
            name="domain-pattern"
            id="domain-pattern"
            placeholder="*"
            value={ranker.domain_pattern}
            onChange={e => updateRanker({ ...ranker, domain_pattern: e.target.value })}
          />
        </div>
        <Switch.Group as="div">
          <Switch.Label className="block text-sm font-medium text-gray-700 -mt-1" passive>
            Organizational
          </Switch.Label>
          <dd className="pt-2 text-sm text-gray-900 sm:mt-1 float-right">
            <Switch
              checked={ranker.apply_to_organization || false}
              onChange={(checked: boolean) => updateRanker({ ...ranker, apply_to_organization: checked })}
              className={classNames(
                ranker.apply_to_organization ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  ranker.apply_to_organization ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>
      </div>
    </>
  );
};
