import { FormEvent, useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Button, Table, Input, CommonRankerConfig } from '@src/components';
import { useNavigate, useParams } from 'react-router';
import {
  useCreateTransactionRanker,
  useGetTransactionRankerById,
  useNotifications,
  useUpdateTransactionRanker
} from '@src/hooks';
import { TransactionSequenceRanker, TransactionRankerType, TransactionMatchingRule } from '@src/models';
import routes from '@src/routes';

const RemoveRuleButton = ({ row, removeRowClicked }: any) => (
  <Button
    small
    danger
    onClick={() => removeRowClicked(row.original as TransactionSequenceRanker)}
    className="float-right"
  >
    Remove
  </Button>
);

const columns = [
  {
    id: 1,
    Header: 'Method',
    accessor: 'method'
  },
  {
    id: 2,
    Header: 'Url Match',
    accessor: 'url_match'
  },
  {
    id: 3,
    Header: 'Content Match',
    accessor: 'content_match'
  },
  {
    id: 4,
    Cell: RemoveRuleButton,
    idAccessor: 'id'
  }
];

const newRuleInitialData: TransactionMatchingRule = {
  method: '',
  url_match: '',
  content_match: undefined
};

type SequenceRankerProps = {
  isEdit?: boolean;
};

export const SequentialStateRanker = ({ isEdit }: SequenceRankerProps) => {
  const navigate = useNavigate();
  const { notifyError } = useNotifications();
  const { id: rankerId } = useParams() as { id: string };

  const { mutateAsync: saveNewRanker } = useCreateTransactionRanker();
  const { mutateAsync: updateRanker } = useUpdateTransactionRanker();

  const { data: rankingRule, isLoading } = useGetTransactionRankerById(rankerId);

  const [ranker, setRanker] = useState<TransactionSequenceRanker>({
    id: '',
    type: TransactionRankerType.TransactionSequenceRanking,
    domain_pattern: '',
    ticket_id: '',
    apply_to_organization: false,
    enabled: true,
    params: {
      target: {
        method: '',
        url_match: '',
        content_match: undefined
      },
      dependencies: []
    }
  });

  useEffect(() => {
    if (!rankingRule) return;

    setRanker(rankingRule as TransactionSequenceRanker);
  }, [rankingRule]);

  const [newFieldRule, setNewFieldRule] = useState<TransactionMatchingRule>(newRuleInitialData);

  const saveRanker = (e: FormEvent) => {
    e.preventDefault();
    if (ranker.params.dependencies.length === 0) {
      alert('Please add at least one dependency transaction.');
      return;
    }

    if (isEdit) {
      updateRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    } else {
      saveNewRanker(ranker).then(() => navigate(routes.CONFIGURATIONS_RANKING));
    }
  };

  const removeRowClicked = (rule: TransactionMatchingRule) => {
    setRanker({
      ...ranker,
      params: {
        target: ranker.params.target,
        dependencies: ranker.params.dependencies.filter(
          d => d.method !== rule.method || d.url_match !== rule.url_match || d.content_match !== rule.content_match
        )
      }
    });
  };

  const addNewRule = () => {
    if (newFieldRule.method.trim() === '' || newFieldRule.url_match.trim() === '') {
      return notifyError({
        title: 'Method and Url match fields are required.',
        timeout: 3000
      });
    }

    if (
      ranker.params.dependencies.some(
        r =>
          r.method === newFieldRule.method &&
          r.url_match === newFieldRule.url_match &&
          r.content_match === newFieldRule.content_match
      )
    ) {
      return notifyError({
        title: 'Dependency transaction is already defined.',
        timeout: 3000
      });
    }

    setRanker({
      ...ranker,
      params: {
        target: ranker.params.target,
        dependencies: [...ranker.params.dependencies, newFieldRule]
      }
    });

    setNewFieldRule(newRuleInitialData);
  };

  if (isLoading) return <></>;

  return (
    <form onSubmit={saveRanker}>
      <div className="space-y-6">
        <CommonRankerConfig
          title="Transaction Sequence Ranking"
          description={<>Configure ranking rules between related transactions.</>}
          ranker={ranker}
          updateRanker={ranker => setRanker(ranker as TransactionSequenceRanker)}
        />

        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-500"></div>
          <span className="flex-shrink mx-4 text-gray-500">Target Transaction</span>
          <div className="flex-grow border-t border-gray-500"></div>
        </div>

        <div className="space-y-2">
          <div className="space-y-1">
            <div className="mt-6 grid grid-flow-col gap-4">
              <TransactionMatchingRuleFields
                isTarget
                transactionMatchingRule={ranker.params.target}
                onMethodChange={method =>
                  setRanker({
                    ...ranker,
                    params: {
                      ...ranker.params,
                      target: {
                        ...ranker.params.target,
                        method
                      }
                    }
                  })
                }
                onUrlMatchChange={urlMatch =>
                  setRanker({
                    ...ranker,
                    params: {
                      ...ranker.params,
                      target: {
                        ...ranker.params.target,
                        url_match: urlMatch
                      }
                    }
                  })
                }
                onContentMatchChange={contentMatch =>
                  setRanker({
                    ...ranker,
                    params: {
                      ...ranker.params,
                      target: {
                        ...ranker.params.target,
                        content_match: contentMatch || undefined
                      }
                    }
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-500"></div>
          <span className="flex-shrink mx-4 text-gray-500">Depends On</span>
          <div className="flex-grow border-t border-gray-500"></div>
        </div>

        <div className="space-y-2">
          <div className="space-y-1">
            <div className="mt-6 grid grid-flow-col gap-4 items-end">
              <TransactionMatchingRuleFields
                transactionMatchingRule={newFieldRule}
                onMethodChange={method =>
                  setNewFieldRule({
                    ...newFieldRule,
                    method
                  })
                }
                onUrlMatchChange={urlMatch =>
                  setNewFieldRule({
                    ...newFieldRule,
                    url_match: urlMatch
                  })
                }
                onContentMatchChange={contentMatch =>
                  setNewFieldRule({
                    ...newFieldRule,
                    content_match: contentMatch || undefined
                  })
                }
              />

              <div className="ml-auto">
                <Button icon={<PlusIcon />} secondary onClick={addNewRule}>
                  Add Rule
                </Button>
              </div>
            </div>
            <div className="border-b border-gray-200">
              <Table columns={columns} data={ranker.params?.dependencies} removeRowClicked={removeRowClicked} />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            onClick={() => navigate(routes.CONFIGURATIONS_RANKING)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

type TransactionMatchingRuleFieldsProps = {
  transactionMatchingRule: TransactionMatchingRule;
  onMethodChange: (method: string) => void;
  onUrlMatchChange: (urlMatch: string) => void;
  onContentMatchChange: (contentMatch?: string) => void;
  isTarget?: boolean;
};

const TransactionMatchingRuleFields = ({
  transactionMatchingRule,
  isTarget,
  onMethodChange,
  onUrlMatchChange,
  onContentMatchChange
}: TransactionMatchingRuleFieldsProps) => {
  return (
    <>
      <div className="col-span-1">
        <label htmlFor="method" className="block text-sm font-medium text-gray-700">
          Method
        </label>
        <select
          id={`${isTarget ? 'method-target' : 'method'}`}
          name={`${isTarget ? 'method-target' : 'method'}`}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={transactionMatchingRule.method}
          onChange={e => onMethodChange(e.target.value)}
        >
          <option value="-">-</option>
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="PATCH">PATCH</option>
          <option value="DELETE">DELETE</option>
        </select>
      </div>

      <div className="col-span-5">
        <Input
          label="Url Match"
          name="url-match"
          id="url-match"
          placeholder="https://example.com/.*/create"
          onFocus={e => e.target.select()}
          value={transactionMatchingRule.url_match}
          onChange={e => onUrlMatchChange(e.target.value)}
        />
      </div>

      <div className="col-span-5">
        <Input
          label="Content Match"
          name="content-match"
          id="content-match"
          placeholder="item:\s\d+"
          onFocus={e => e.target.select()}
          value={transactionMatchingRule.content_match}
          onChange={e => onContentMatchChange(e.target.value)}
        />
      </div>
    </>
  );
};
