type HeadersPreviewerProps = {
  headers?: [string, string][];
};

export const HeadersPreviewer = ({ headers }: HeadersPreviewerProps) => {
  if (!headers) return <div>No headers</div>;
  return (
    <div className="max-h-96 mt-2 p-2 border border-gray-300 rounded-md font-mono text-xs overflow-auto">
      {headers &&
        Object.values(headers).map(([key, val]) => {
          return (
            <p key={key}>
              <span className="font-bold"> {key}: </span> {val}
            </p>
          );
        })}
    </div>
  );
};
