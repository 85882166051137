import { Breadcrumb, BreadcrumbItem, EditorRulesList } from '@src/components';
import routes from '@src/routes';

export const EditorRulesListPageBreadCrumb = () => {
  return (
    <Breadcrumb>
      <BreadcrumbItem to={routes.CONFIGURATIONS} label="Configurations" />
      <BreadcrumbItem to={routes.CONFIGURATIONS_EDITOR_RULES} label="Editor Rules" />
    </Breadcrumb>
  );
};

export const EditorRulesListPage = () => {
  return (
    <>
      <EditorRulesListPageBreadCrumb />
      <div className="h-full py-6">
        <EditorRulesList />
      </div>
    </>
  );
};
