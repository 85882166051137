export enum TransactionRankerType {
  JsonRanking = 'JsonRanking',
  HeaderRanking = 'HeaderRanking',
  TransactionSequenceRanking = 'TransactionSequenceRanking',
  TransactionSequenceRankingV2 = 'TransactionSequenceRankingV2',
  ResponseCodeRanking = 'ResponseCodeRanking',
  JsonProximityRanking = 'JsonProximityRanking'
}

export const TransactionRankerLabels: { [key: string]: { label: string; color: string } } = {
  [TransactionRankerType.JsonRanking]: {
    label: 'Json Ranking Rule',
    color: 'blue'
  },
  [TransactionRankerType.HeaderRanking]: {
    label: 'Header Ranking Rule',
    color: 'green'
  },
  [TransactionRankerType.TransactionSequenceRanking]: {
    label: 'Workflow Rule',
    color: 'teal'
  },
  [TransactionRankerType.TransactionSequenceRankingV2]: {
    label: 'Workflow Rule V2',
    color: 'teal'
  },
  [TransactionRankerType.ResponseCodeRanking]: {
    label: 'Response Code Ranking Rule',
    color: 'orange'
  },
  [TransactionRankerType.JsonProximityRanking]: {
    label: 'Json Proximity Ranking Rule',
    color: 'neon'
  }
};

export type TransactionRanker<T extends TransactionRankerType, D> = {
  id: string;
  type: T;
  params: D;
  created_at?: number;
  updated_at?: number;
  organization_id?: string;
  recording_id?: string;
  apply_to_organization?: boolean;
  domain_pattern?: string;
  enabled?: boolean;
  automated?: boolean;
  ticket_id?: string;
};

/* Json Ranking models */
export type JsonRankingRule = {
  path: string;
  score: number;
  strict_list_order: boolean;
  use_lightweight_search: boolean;
};

export type JsonRankingParams = {
  rules: JsonRankingRule[];
};

export enum JsonProximityRankingAlgorithms {
  HammingDistance = 'hamming_distance',
  Levenshtein = 'levenshtein_distance',
  LongestCommonSubsequence = 'longest_common_subsequence',
  LongestCommonSubstring = 'longest_common_substring'
}

export type JsonProximityRankingRule = {
  path: string;
  score: number;
  algorithm: JsonProximityRankingAlgorithms | null;
  sort_string: boolean;
  ignore_case: boolean;
};

export type JsonProximityRankingParams = {
  rules: JsonProximityRankingRule[];
};

/* Header Ranking models */
export type HeaderRankingRule = {
  header: string;
  score: number;
};

export type HeaderRankingParams = {
  rules: HeaderRankingRule[];
};

export type ResponseCodeRankingRule = {
  status_code: string;
  score: number;
};

export type ResponseCodeRankingParams = {
  rules: ResponseCodeRankingRule[];
};

/* Transaction Sequence Ranking models */
export type TransactionMatchingRule = {
  method: string;
  url_match: string;
  content_match?: string;
};

export type TransactionSequenceParams = {
  target: TransactionMatchingRule;
  dependencies: TransactionMatchingRule[];
  score?: number;
};

/* Ranker types */
export type TransactionSequenceRanker = TransactionRanker<
  TransactionRankerType.TransactionSequenceRanking,
  TransactionSequenceParams
>;

export type TransactionSequenceRankerV2 = TransactionRanker<
  TransactionRankerType.TransactionSequenceRankingV2,
  TransactionSequenceParams
>;

export type JsonTransactionRanker = TransactionRanker<TransactionRankerType.JsonRanking, JsonRankingParams>;

export type HeaderTransactionRanker = TransactionRanker<TransactionRankerType.HeaderRanking, HeaderRankingParams>;

export type ResponseCodeTransactionRanker = TransactionRanker<
  TransactionRankerType.ResponseCodeRanking,
  ResponseCodeRankingParams
>;

export type JsonProximityTransactionRanker = TransactionRanker<
  TransactionRankerType.JsonProximityRanking,
  JsonProximityRankingParams
>;

export type AnyTransactionRanker =
  | JsonTransactionRanker
  | HeaderTransactionRanker
  | TransactionSequenceRanker
  | TransactionSequenceRankerV2
  | ResponseCodeTransactionRanker
  | JsonProximityTransactionRanker;
