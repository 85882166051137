import { hasAnyResource, StudioImportResult, StudioTransportData, StudioTransportOption } from '@src/models';
import { getExportOptions, exportConfiguration, importConfiguration } from '@src/services';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNotifications } from './notification';

export const useGetExportOptions = () => {
  const queryClient = useQueryClient();
  const invalidateTransactions = () => queryClient.invalidateQueries('export-options');

  const { data, isLoading, error } = useQuery<StudioTransportOption[]>('export-options', getExportOptions);

  return { data, isLoading, error, invalidateTransactions };
};

export const useExportConfiguration = () => {
  const { notifyWarn } = useNotifications();

  const { mutate, isLoading, error } = useMutation((exportOptions: StudioTransportOption[]) => {
    if (hasAnyResource(exportOptions)) {
      return exportConfiguration(exportOptions);
    }

    notifyWarn({
      title: 'Configuration is empty, nothing to export!'
    });

    return Promise.resolve();
  });

  return { mutate, isLoading, error };
};

export const useImportConfiguration = (callback: (results: StudioImportResult[]) => void) => {
  const { notifySuccess, notifyWarn, notifyError } = useNotifications();

  const { mutate, isLoading, error } = useMutation((importData: StudioTransportData) =>
    importConfiguration(importData)
      .then(results => {
        const allAreImported = results.every(res => res.isImported);

        if (allAreImported) {
          notifySuccess({
            title: 'Configuration successfully imported!'
          });
        } else {
          notifyWarn({
            title: 'Configuration import failed.'
          });
        }

        callback(results);
      })
      .catch(e => {
        notifyError({
          title: 'Could not import configuration',
          content: 'Check network tab for more details.'
        });

        throw e;
      })
  );

  return { mutate, isLoading, error };
};
