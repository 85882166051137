import { useLocation, useParams } from 'react-router';
import { Breadcrumb, BreadcrumbItem, TransactionDetails } from '@src/components';
import routes from '@src/routes';

export const TransactionDetailsPage = () => {
  const params = useParams<{ id: string }>() as { id: string };

  const location = useLocation();
  const locationState = location.state as { previousPage: string };
  const previousPage = locationState.previousPage;

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem
          to={locationState.previousPage}
          label={previousPage === routes.TRANSACTIONS ? 'Transactions' : 'Ranked Transactions'}
        />
        <BreadcrumbItem to={window.location.pathname} label={params.id} />
      </Breadcrumb>
      <div className="py-6">
        <TransactionDetails />
      </div>
    </>
  );
};
