import { Table } from '@src/components';
import { formatDatetimeString } from '@src/utils';
import { useGetCache } from '@src/hooks/cache';

const DateCell = ({ cell }: any) => {
  return <span> {formatDatetimeString(cell.value)} </span>;
};

const columns = [
  {
    id: 1,
    Header: 'Id',
    accessor: 'id'
  },
  {
    id: 2,
    Header: 'Key',
    accessor: 'key'
  },
  {
    id: 3,
    Header: 'Transaction Id',
    accessor: 'value'
  },
  {
    id: 4,
    Header: 'Expiration Time',
    accessor: 'expiration_time',
    Cell: DateCell
  }
];

export const CacheList = () => {
  const { data: allCache } = useGetCache();

  if (!allCache) {
    return null;
  }
  return (
    <div>
      <Table
        columns={columns}
        data={allCache}
        enablePagination
        saveTableState
        enableGlobalFilter
        rowPropsFn={(row: any) => ({
          style: {
            borderLeftColor:
              row.original.status_code >= 400 ? 'red' : row.original.status_code >= 300 ? 'orange' : 'lime',
            borderLeftWidth: '2px'
          }
        })}
      ></Table>
    </div>
  );
};
