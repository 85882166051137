type VideoPreviewerProps = {
  content: Blob;
  contentType?: string;
};

export const VideoPreviewer = ({ content, contentType }: VideoPreviewerProps) => {
  return (
    <>
      <p className="italic">{contentType}</p>
      <video className="max-h-96" controls src={URL.createObjectURL(content)}></video>
    </>
  );
};
