import { useParams } from 'react-router-dom';
import { useGetBackgroundJobById } from '@src/hooks';
import { JsonPreviewer } from '@src/components';
import { AnyBackgroundJob, BackgroundJobLabels, BackgroundJobType } from '@src/models';
import { ArrowPathIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { TransactionValidationOutput } from './jobs';

export const renderBackgroundJobStatus = (status: string) => {
  switch (status) {
    case 'PENDING':
      return (
        <div className="flex flex-row justify-start items-center gap-2">
          <ArrowPathIcon className="h-6 w-6 animate-spin" />
          Pending
        </div>
      );
    case 'IN_PROGRESS':
      return (
        <div className="flex flex-row justify-start items-center gap-2">
          <ArrowPathIcon className="h-6 w-6 animate-spin" />
          In progress
        </div>
      );
    case 'DONE':
      return (
        <div className="flex flex-row justify-start items-center gap-2">
          <CheckCircleIcon className="h-6 w-6 text-green-500" />
          Done
        </div>
      );
    case 'ERROR':
      return (
        <div className="flex flex-row justify-start items-center gap-2">
          <XCircleIcon className="h-6 w-6 text-red-500" />
          Error
        </div>
      );
  }
};

export const renderBackgroundJobOutput = (job: AnyBackgroundJob) => {
  if (!job.output) {
    return null;
  }

  switch (job.type) {
    case BackgroundJobType.TransactionValidation:
      return <TransactionValidationOutput output={job.output} />;
    default:
      return <JsonPreviewer contentText={job.output} />;
  }
};

export const BackgroundJobDetails = () => {
  const params = useParams<{ id: string }>() as { id: string };

  const { data: job } = useGetBackgroundJobById(params.id);

  if (!job) {
    return <>Couldn't load a page. TODO: Add error page...</>;
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Job Details</h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Id</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{job.job_id}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{BackgroundJobLabels[job.type].label}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Params</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {job.params && <JsonPreviewer contentText={JSON.stringify(job.params)} />}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {renderBackgroundJobStatus(job.status as string)}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Output</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{renderBackgroundJobOutput(job)}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Message</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{job.message}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Error</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{job.error}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
