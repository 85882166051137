import { CacheList } from '@src/components/Cache';
import { CacheStatistics } from '@src/components/Statistics/CacheStatistics';
import { MenuButton } from '@src/components';
import { useGetRecordingDetails } from '@src/hooks';
import { useInvalidateCache } from '@src/hooks/cache';

export const CacheListPage = () => {
  const { data: recordingDetails } = useGetRecordingDetails();
  const { mutateAsync: invalidateCache } = useInvalidateCache();

  return (
    <div>
      <div className="my-5">
        <CacheStatistics />
      </div>
      <div className="flex justify-end">
        <div className="mt-6">
          {recordingDetails?.recording_id && recordingDetails.organization_id && (
            <MenuButton
              title="Actions"
              items={[
                {
                  name: 'Invalidate clone cache',
                  onClick: () => invalidateCache({ cacheLevel: 'recording', id: recordingDetails?.recording_id })
                },
                {
                  name: 'Invalidate organization cache',
                  onClick: () => invalidateCache({ cacheLevel: 'organization', id: recordingDetails?.organization_id })
                }
              ]}
            />
          )}
        </div>
      </div>
      <CacheList />
    </div>
  );
};
