import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useGetTransactions, useUpdateTransactionEnabled } from '@src/hooks';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Button, SelectColumnFilter, Table, TruncatedCell } from '@src/components';
import routes from '@src/routes';
import { formatByteSize } from '@src/utils';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { Transaction } from '@src/models';

const EditButton = ({ column, row }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      small
      secondary
      icon={<PencilSquareIcon />}
      onClick={() =>
        navigate(generatePath(routes.TRANSACTION_DETAILS, { id: row.original[column.idAccessor] }), {
          state: { previousPage: location.pathname }
        })
      }
      className="float-right"
    >
      Edit
    </Button>
  );
};

const SizeCell = ({ cell }: any) => {
  return <span> {formatByteSize(cell.value)} </span>;
};

const TransactionStatusToggle = ({ column, row }: any) => {
  const transaction: Transaction = row.original;
  const { mutate: updateTransactionData } = useUpdateTransactionEnabled(transaction.id);

  const updateTransactionStatus = (transaction: Transaction, isDisabled: boolean) => {
    if (!transaction) return;
    transaction.is_disabled = isDisabled;

    updateTransactionData({ transaction });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="w-3/4 md:w-1/2">
        <label htmlFor="domain-pattern" className="block text-sm font-medium text-gray-700"></label>
        <div className="mt-1"></div>
      </div>

      <Switch.Group as="div">
        <dd className="pt-2 text-sm text-gray-900 sm:mt-1 float-right">
          <Switch
            checked={transaction.is_disabled}
            onChange={(isDisabled: boolean) => updateTransactionStatus(transaction, isDisabled)}
            className={classNames(
              transaction.is_disabled ? 'bg-gray-200' : 'bg-indigo-600',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:ml-auto'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                row.original.is_disabled ? 'translate-x-0' : 'translate-x-5',
                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
        </dd>
      </Switch.Group>
    </div>
  );
};

const columns = [
  {
    id: 1,
    Header: 'Id',
    accessor: 'id'
  },
  {
    id: 2,
    Header: 'Method',
    accessor: 'method',
    Filter: SelectColumnFilter,
    filter: 'includes'
  },
  {
    id: 3,
    Header: 'Size',
    accessor: 'size',
    Cell: SizeCell
  },
  {
    id: 4,
    Header: 'Url',
    accessor: 'url',
    Cell: TruncatedCell
  },
  {
    id: 5,
    Header: 'Status Code',
    accessor: 'status_code'
  },
  {
    id: 6,
    Header: 'Status',
    accessor: 'is_disabled',
    Cell: TransactionStatusToggle
  },
  {
    id: 7,
    Cell: EditButton,
    idAccessor: 'id'
  }
];

export const TransactionList = () => {
  const { data: allTransactions } = useGetTransactions();

  if (!allTransactions) {
    return null;
  }

  return (
    <div>
      <Table
        columns={columns}
        data={allTransactions.data}
        enableGlobalFilter
        enablePagination
        saveTableState
        rowPropsFn={(row: any) => ({
          style: {
            borderLeftColor:
              row.original.status_code >= 400 ? 'red' : row.original.status_code >= 300 ? 'orange' : 'lime',
            borderLeftWidth: '2px'
          }
        })}
      ></Table>
    </div>
  );
};
