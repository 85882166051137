import { StudioImportResult } from '@src/models';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { TransportOptionLink } from '@src/components';

export const ConfigurationResult = ({ results }: { results: StudioImportResult[] }) => {
  return (
    <ul>
      {results.map(r => {
        const itemName = `${r.group_name} / ${r.resource_name}`;

        return (
          <li
            className="flex justify-start items-center gap-2"
            key={`${r.group_name}-${r.resource_name}-import-result`}
          >
            {r.isImported ? (
              <CheckCircleIcon className="h-5 w-5 text-green-500" />
            ) : (
              <XCircleIcon className="h-5 w-5 text-red-500" />
            )}
            <span className="text-sm">{r.link ? <TransportOptionLink name={itemName} link={r.link} /> : itemName}</span>
            {r.error && <span className="text-xs text-red-500">- {r.error}</span>}
          </li>
        );
      })}
    </ul>
  );
};
