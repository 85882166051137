import { Link } from 'react-router-dom';
import classNames from 'classnames';
import routes from '@src/routes';

const RecorderBadge = () => (
  <span className="inline-flex items-center px-3 py-0.5 mr-2 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
    Recorder
  </span>
);

const EditorBadge = () => (
  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
    Editor
  </span>
);

const actions = [
  {
    title: 'Data Rules',
    description: 'Lets you modify requests and responses during recording and playback phases.',
    route: routes.CONFIGURATIONS_DYNAMIC_PIPELINE,
    icon: ['recorder'],
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50'
  },
  {
    title: 'Workflows and Transactions Ranking',
    description: 'Configure Transaction Rankers and Workflows Rules to handle Transaction Ranking issues.',
    route: routes.CONFIGURATIONS_RANKING,
    icon: ['recorder'],
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50'
  },
  {
    title: 'Editor Rules',
    description:
      "Using Editor rules you can configure the editor's behavior for every organization, domain, and clone.",
    route: routes.CONFIGURATIONS_EDITOR_RULES,
    icon: ['recorder', 'editor'],
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50'
  },
  {
    title: 'Export / Import',
    description: "Export and import organization's configuration.",
    route: routes.CONFIGURATIONS_EXPORT,
    icon: [],
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50'
  }
];

export const Configuration = () => {
  return (
    <>
      <div className="flex">
        <div className="max-w-7xl m-auto px-4 sm:px-6 lg:px-8">
          <div className="rounded-lg bg-gray-300 shadow-md divide-y divide-gray-400 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
            {actions.map((action, actionIdx) => (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                  actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                  actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                  'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                )}
              >
                <div>
                  {action.icon.map(i => (
                    <>
                      {i === 'recorder' && <RecorderBadge />}
                      {i === 'editor' && <EditorBadge />}
                    </>
                  ))}
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium">
                    <Link to={action.route} className="focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.title}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">{action.description}</p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
