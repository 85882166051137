import request, { apiRequest } from '../request';
import { PageResponse } from '@src/models';
import { CacheEntry, CacheStatistics, InvalidateCacheParams } from '@src/models/cache';

const API_ENDPOINTS = {
  PREFIX: '/cache',
  STATISTICS: '/statistics',
  CLEAR: '/invalidate'
};

export const getCache = async (): Promise<PageResponse<CacheEntry[]>> =>
  (await apiRequest(request.get(API_ENDPOINTS.PREFIX))).content;

export const getCacheStatistics = async (): Promise<CacheStatistics> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.PREFIX}${API_ENDPOINTS.STATISTICS}`))).content;

export const invalidateCache = async (invalidateCacheParams: InvalidateCacheParams): Promise<void> =>
  (
    await apiRequest(
      request.delete(
        `${API_ENDPOINTS.PREFIX}${API_ENDPOINTS.CLEAR}/${invalidateCacheParams.cacheLevel}/${invalidateCacheParams.id}`
      )
    )
  ).content;
