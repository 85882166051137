import { useParams } from 'react-router';
import { EditorRulesLabels, EditorRulesType } from '@src/models';
import {
  Breadcrumb,
  BreadcrumbItem,
  ExternalDomTreeScoreThresholdRule,
  GenericEditRule,
  QueryParamsEditRule
} from '@src/components';
import { TransactionRankersListPageBreadCrumb } from '@src/pages';

export const EditorRulePage = ({ isEdit }: { isEdit?: boolean }) => {
  const params = useParams<{ editorRuleType: string; id: string }>() as { editorRuleType: string; id: string };

  let editorRuleComponent = null;
  switch (params.editorRuleType) {
    case EditorRulesType.GenericRule:
      editorRuleComponent = <GenericEditRule isEdit={isEdit} />;
      break;
    case EditorRulesType.QueryParamsRule:
      editorRuleComponent = <QueryParamsEditRule isEdit={isEdit} />;
      break;
    case EditorRulesType.ExternalDomTreeScoreThresholdRule:
      editorRuleComponent = <ExternalDomTreeScoreThresholdRule isEdit={isEdit} />;
      break;
  }

  if (!editorRuleComponent) {
    return <>Editor rule does not exist!</>;
  }

  return (
    <>
      <Breadcrumb>
        <TransactionRankersListPageBreadCrumb />
        <BreadcrumbItem to={window.location.pathname} label={EditorRulesLabels[params.editorRuleType]?.label} />
        <BreadcrumbItem to={window.location.pathname} label={isEdit ? params.id : 'New'} />
      </Breadcrumb>
      <div className="h-full py-6">{editorRuleComponent}</div>
    </>
  );
};
