import { Breadcrumb, BreadcrumbItem, DynamicContentModPreview } from '@src/components';
import routes from '@src/routes';

export const DynamicContentModPreviewPage = () => {
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem to={routes.CONFIGURATIONS} label="Configurations" />
        <BreadcrumbItem to={window.location.pathname} label="Data Rules" />
      </Breadcrumb>
      <div className="h-full py-6">
        <DynamicContentModPreview />
      </div>
    </>
  );
};
