import { generatePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useGetRankedTransactions } from '@src/hooks';
import { Button, Input, SelectColumnFilter, Table, TruncatedCell } from '@src/components';
import routes from '@src/routes';
import { useState } from 'react';
import { useEffect } from 'react';

const EditButton = ({ column, row }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      small
      secondary
      icon={<PencilSquareIcon />}
      onClick={() =>
        navigate(generatePath(routes.TRANSACTION_DETAILS, { id: row.original[column.idAccessor] }), {
          state: { previousPage: location.pathname }
        })
      }
      className="float-right"
    >
      Edit
    </Button>
  );
};

const columns = [
  {
    id: 1,
    Header: 'Id',
    accessor: 'id'
  },
  {
    id: 2,
    Header: 'Method',
    accessor: 'method',
    Filter: SelectColumnFilter,
    filter: 'includes'
  },
  {
    id: 3,
    Header: 'Url',
    accessor: 'url',
    Cell: TruncatedCell
  },
  {
    id: 4,
    Header: 'Score',
    accessor: 'score'
  },
  {
    id: 5,
    Cell: EditButton,
    idAccessor: 'id'
  }
];

export const RankedTransactionList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestId, setRequestId] = useState<string>(searchParams.get('requestId') || '');
  const { data: rankedTransactions } = useGetRankedTransactions(searchParams.get('requestId') || '');

  useEffect(() => {
    setRequestId(searchParams.get('requestId') || '');
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateSearchParams = () => {
    if (requestId) {
      searchParams.set('requestId', requestId);
    } else {
      searchParams.delete('requestId');
    }
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col gap-4">
      <Input
        label="Request Id"
        className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5"
        value={requestId}
        onChange={e => setRequestId(e.target.value)}
        action={
          <Button secondary className="ml-2" onClick={() => updateSearchParams()}>
            Search
          </Button>
        }
      />
      <Table
        columns={columns}
        data={rankedTransactions?.data || []}
        enableGlobalFilter
        enablePagination
        saveTableState
      />
    </div>
  );
};
