import { TextPreviewer, ImagePreviewer, VideoPreviewer, JsonPreviewer, SingleFileUpload } from '@src/components';
import { isJsonContent } from '@src/utils';
import { useState } from 'react';

export type DataPreviewerProps = {
  content?: Blob;
  headers?: { [key: string]: string };
  originalUrl?: string;
} & (
  | {
      replaceable: true;
      onFileUploaded: (file: File, applyToAllTransactions: boolean) => void;
    }
  | {
      replaceable: false;
      onFileUploaded?: (file: File, applyToAllTransactions: boolean) => void;
    }
);

const getFileName = (url: string): string => new URL(url || '').pathname.split('/').pop() || 'data';

export const DataPreviewer = ({ replaceable, content, headers, originalUrl, onFileUploaded }: DataPreviewerProps) => {
  const [contentText, setContentText] = useState<string | null>(null);
  content?.text().then(setContentText);

  if (!content || content?.size === 0) return <div>No data</div>;

  const contentType = headers && headers['content-type'];

  let previewer = (
    <div>
      The content couldn't be previewed.
      <a
        className="ml-2 underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
        href={URL.createObjectURL(content)}
        download={originalUrl ? getFileName(originalUrl) : 'data'}
      >
        Download
      </a>
    </div>
  );

  if (contentType?.startsWith('text/') || contentType?.startsWith('application/javascript')) {
    previewer = <TextPreviewer content={content} />;
  }
  if (contentType?.startsWith('application/json') || isJsonContent(contentText)) {
    previewer = <JsonPreviewer content={content} contentType={contentType} />;
  }
  if (contentType?.startsWith('image/')) {
    previewer = <ImagePreviewer content={content} contentType={contentType} />;
  }
  if (contentType?.startsWith('video/')) {
    previewer = <VideoPreviewer content={content} contentType={contentType} />;
  }

  if (replaceable) {
    return (
      <div className="flex flex-col">
        {previewer}
        <div className="sm:col-span-6 my-4">
          <label className="block text-sm font-medium text-gray-700">Upload a new file</label>
          <SingleFileUpload
            onFileUploaded={(file, applyToAllTransactions) => onFileUploaded?.(file, applyToAllTransactions)}
          />
        </div>
      </div>
    );
  } else {
    return previewer;
  }
};
