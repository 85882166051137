import { createGlobalState } from 'react-hooks-global-state';
import { Notification } from '@src/models';

export enum State {
  NOTIFICATIONS = 'notifications'
}

const initialState = {
  [State.NOTIFICATIONS]: [] as Notification[]
};

const { useGlobalState, getGlobalState, setGlobalState } = createGlobalState(initialState);

function resetGlobalState() {
  setGlobalState(State.NOTIFICATIONS, []);
}

export { useGlobalState, getGlobalState, resetGlobalState };
