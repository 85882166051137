import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { Logo } from '@src/components';
import { RecordingDetails } from '@src/models/';
import classNames from 'classnames';

export type NavigationItem = {
  name: string;
  description: string;
  route: string;
};

type Props = {
  items: NavigationItem[];
  info?: RecordingDetails;
};

export const Navbar = (props: Props) => {
  return (
    <div className="bg-gray-100 shadow-md">
      <NavigationMenuPrimitive.Root className="relative z-40">
        <NavigationMenuPrimitive.List className="flex flex-row rounded-lg dark:bg-gray-800 p-2 space-x-2">
          <NavigationMenuPrimitive.Item className="flex items-center mb-1 mx-5  ">
            <Logo width={94} height={14} />
          </NavigationMenuPrimitive.Item>
          <NavigationMenuPrimitive.Item className="flex-1">
            <NavigationMenuPrimitive.Trigger
              className={classNames([
                'px-3 py-2 text-sm rounded-md hover:bg-gray-100 dark:hover:bg-gray-900',
                'text-sm font-medium text-gray-700 dark:text-gray-100',
                'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
              ])}
            >
              Services
            </NavigationMenuPrimitive.Trigger>
            <NavigationMenuPrimitive.Content
              className={classNames(
                'relative rounded-lg',
                'radix-motion-from-start:animate-enter-from-left',
                'radix-motion-from-end:animate-enter-from-right',
                'radix-motion-to-start:animate-exit-to-left',
                'radix-motion-to-end:animate-exit-to-right'
              )}
            >
              <div className="w-[16rem] lg:w-[18rem] p-3">
                <div className="w-full flex flex-col space-y-2">
                  {props.items.map(item => (
                    <NavigationMenuPrimitive.Link
                      key={item.name}
                      className={classNames(
                        'w-full px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-md',
                        'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
                      )}
                      href={item.route}
                    >
                      <span className="text-sm font-medium text-gray-900 dark:text-gray-100">{item.name}</span>

                      <div className="mt-1 text-sm text-gray-700 dark:text-gray-400">{item.description}</div>
                    </NavigationMenuPrimitive.Link>
                  ))}
                </div>
              </div>
            </NavigationMenuPrimitive.Content>
          </NavigationMenuPrimitive.Item>

          {props.info && (
            <NavigationMenuPrimitive.Item className="flex items-center justify-end pr-5">
              <div
                className="grid grid-cols-2 text-xs font-medium text-gray-700 dark:text-gray-100 gap-y-0.5 gap-x-2"
                style={{ gridTemplateColumns: 'auto auto' }}
              >
                <div className="col-span-1">Recording id: </div>
                <div>{props.info?.recording_id}</div>
                <div className="col-span-1">Organization id:</div>
                <div>{props.info?.organization_id}</div>
              </div>
            </NavigationMenuPrimitive.Item>
          )}
        </NavigationMenuPrimitive.List>

        <div
          className={classNames('absolute flex justify-start', 'left-[1%] top-[100%]', 'z-10')}
          style={{
            perspective: '2000px'
          }}
        >
          <NavigationMenuPrimitive.Viewport
            className={classNames(
              'relative mt-2 shadow-lg rounded-md bg-white dark:bg-gray-800 overflow-hidden',
              'w-radix-navigation-menu-viewport',
              'h-radix-navigation-menu-viewport',
              'radix-state-open:animate-scale-in-content',
              'radix-state-closed:animate-scale-out-content',
              'origin-[top_center] transition-[width_height] duration-300 ease-[ease]'
            )}
          />
        </div>
      </NavigationMenuPrimitive.Root>
    </div>
  );
};
