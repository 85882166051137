import request, { apiRequest } from '../request';
import { AnyTransactionRanker } from '@src/models';

const API_ENDPOINTS = {
  TRANSACTION_RANKING: '/rules/ranking'
};

export const getTransactionRankers = async (): Promise<AnyTransactionRanker[]> =>
  (await apiRequest(request.get(API_ENDPOINTS.TRANSACTION_RANKING))).content;

export const getTransactionRankerById = async (rankerId: string): Promise<AnyTransactionRanker | undefined> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.TRANSACTION_RANKING}/${rankerId}`))).content;

export const createTransactionRanker = async (ranker: AnyTransactionRanker): Promise<AnyTransactionRanker> => {
  return (await apiRequest(request.post(API_ENDPOINTS.TRANSACTION_RANKING, ranker))).content;
};

export const updateTransactionRanker = async (ranker: AnyTransactionRanker): Promise<AnyTransactionRanker> => {
  return (await apiRequest(request.put(`${API_ENDPOINTS.TRANSACTION_RANKING}/${ranker.id}`, ranker))).content;
};

export const deleteTransactionRanker = async (rankerId: string): Promise<AnyTransactionRanker | undefined> =>
  (await apiRequest(request.delete(`${API_ENDPOINTS.TRANSACTION_RANKING}/${rankerId}`))).content;
