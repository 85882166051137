import { formatDistance } from 'date-fns';

export const JsonRepresentation = ({ cell }: any) => {
  return (
    <p className="mt-2 p-2 w-full font-mono text-xs whitespace-pre overflow-auto">
      {JSON.stringify(
        cell.value,
        (key, value) => {
          if (value) return value;
        },
        2
      )}
    </p>
  );
};

export const DateDistanceRepresentation = ({ cell }: any) => {
  return <>{formatDistance(new Date(cell.value * 1000), new Date(), { addSuffix: true })}</>;
};

export const TruncatedCell = ({ cell }: any) => {
  return (
    <div
      style={
        {
          'text-wrap': 'wrap',
          'word-break': 'break-all',
          display: '-webkit-box',
          '-webkit-line-clamp': '1',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden'
        } as React.CSSProperties
      }
      className="text-sm text-gray-900"
    >
      {cell.value}
    </div>
  );
};
