export type StudioTransportOption = {
  id: string;
  name: string;
  ticket_id: string;
  children: StudioTransportOption[];
  checked: boolean;
  is_resource: boolean;
  link?: string;
};

export type StudioImportResult = {
  group_name: string;
  resource_name: string;
  isImported: boolean;
  error?: string;
  link?: string;
};

export type StudioTransportData = { [key: string]: { [key: string]: any }[] };

export const hasAnyResource = (options: StudioTransportOption[]): boolean => {
  const optionNodes = [...options];
  while (optionNodes.length) {
    const option = optionNodes.pop();
    if (option?.is_resource) {
      return true;
    }
    option?.children && optionNodes.push(...option.children);
  }
  return false;
};
