import { useParams } from 'react-router';
import { BackgroundJobDetails, Breadcrumb, BreadcrumbItem } from '@src/components';
import routes from '@src/routes';

export const BackgroundJobDetailsPage = () => {
  const params = useParams<{ id: string }>() as { id: string };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem to={routes.BACKGROUND_JOBS} label="Jobs" />
        <BreadcrumbItem to={window.location.pathname} label={params.id} />
      </Breadcrumb>
      <div className="py-6">
        <BackgroundJobDetails />
      </div>
    </>
  );
};
