import { useParams } from 'react-router';
import { TransactionRankerLabels, TransactionRankerType } from '@src/models';
import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderRanker,
  JsonBodyRanker,
  SequentialStateRanker,
  ResponseCodeRanker,
  JsonProximityRanker,
  SequentialStateRankerV2
} from '@src/components';
import { TransactionRankersListPageBreadCrumb } from '@src/pages';

export const TransactionRankerPage = ({ isEdit }: { isEdit?: boolean }) => {
  const params = useParams<{ rankerType: string; id: string }>() as { rankerType: string; id: string };

  let rankerComponent = null;
  switch (params.rankerType) {
    case TransactionRankerType.HeaderRanking:
      rankerComponent = <HeaderRanker isEdit={isEdit} />;
      break;
    case TransactionRankerType.JsonRanking:
      rankerComponent = <JsonBodyRanker isEdit={isEdit} />;
      break;
    case TransactionRankerType.TransactionSequenceRanking:
      rankerComponent = <SequentialStateRanker isEdit={isEdit} />;
      break;
    case TransactionRankerType.TransactionSequenceRankingV2:
      rankerComponent = <SequentialStateRankerV2 isEdit={isEdit} />;
      break;
    case TransactionRankerType.ResponseCodeRanking:
      rankerComponent = <ResponseCodeRanker isEdit={isEdit} />;
      break;
    case TransactionRankerType.JsonProximityRanking:
      rankerComponent = <JsonProximityRanker isEdit={isEdit} />;
      break;
  }

  if (!rankerComponent) {
    return <>Ranker type does not exist!</>;
  }

  return (
    <>
      <Breadcrumb>
        <TransactionRankersListPageBreadCrumb />
        <BreadcrumbItem to={window.location.pathname} label={TransactionRankerLabels[params.rankerType]?.label} />
        <BreadcrumbItem to={window.location.pathname} label={isEdit ? params.id : 'New'} />
      </Breadcrumb>
      <div className="h-full py-6">{rankerComponent}</div>
    </>
  );
};
