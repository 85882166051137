import { useQuery, useQueryClient } from 'react-query';
import { RecordingStatistics, RecordingDetails } from '@src/models';
import { getRecordingStatistics, getRecordingDetails } from '@src/services';

export const useGetRecordingStatistics = () => {
  const queryClient = useQueryClient();
  const invalidateRecordingStatistics = () => queryClient.invalidateQueries('recording-statistics');

  const { data, isLoading, error } = useQuery<RecordingStatistics>('recording-statistics', () =>
    getRecordingStatistics()
  );

  return { data, isLoading, error, invalidateRecordingStatistics };
};

export const useGetRecordingDetails = () => {
  const { data, isLoading, error } = useQuery<RecordingDetails>('recording-details', () => getRecordingDetails());
  return { data, isLoading, error };
};
