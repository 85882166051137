import request, { apiRequest } from '../request';
import { StudioImportResult, StudioTransportData, StudioTransportOption } from '@src/models';

const API_ENDPOINTS = {
  EXPORT: '/transport/export',
  IMPORT: '/transport/import'
};

export const getExportOptions = async (): Promise<StudioTransportOption[]> =>
  (await apiRequest(request.get(API_ENDPOINTS.EXPORT))).content;

export const exportConfiguration = async (exportOptions: StudioTransportOption[]) =>
  await apiRequest(request.post(`${API_ENDPOINTS.EXPORT}?dl=1`, exportOptions, { responseType: 'blob' })).then(
    response => {
      const url = window.URL.createObjectURL(new Blob([response.content]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export-${new Date().toISOString()}.json`);
      document.body.appendChild(link);
      link.click();
    }
  );

export const importConfiguration = async (data: StudioTransportData): Promise<StudioImportResult[]> =>
  (await apiRequest(request.post(API_ENDPOINTS.IMPORT, data))).content;
