import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { generatePath, useNavigate } from 'react-router';
import { CheckOrCrossIcon, Button, MenuButton, Table } from '@src/components';
import { useDeleteEditorRule, useGetEditorRules } from '@src/hooks';
import { EditorRulesType, AnyEditorRule, EditorRulesLabels } from '@src/models';
import { isEmpty } from '@src/utils';
import routes from '@src/routes';

const ToRuleLabel = ({ cell }: any) => {
  const labels = {
    [EditorRulesType.GenericRule]: 'Editor Generic Rule',
    [EditorRulesType.QueryParamsRule]: 'Editor QueryParams Rule',
    [EditorRulesType.ExternalDomTreeScoreThresholdRule]: 'Editor External DOM Tree Score Treshold Rule'
  };

  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
      {labels[cell.value as EditorRulesType]}
    </span>
  );
};

const ActionButton = ({ row }: any) => {
  const id = (row.original as AnyEditorRule).id;
  const editorRuleType = (row.original as AnyEditorRule).type;
  const navigate = useNavigate();
  const { mutate: deleteEditRule } = useDeleteEditorRule();

  return (
    <div className="flex justify-end gap-4">
      <Button
        small
        secondary
        icon={<PencilSquareIcon />}
        onClick={() => navigate(generatePath(routes.CONFIGURATIONS_EDITOR_RULE_EDIT, { editorRuleType, id }))}
      >
        Edit
      </Button>
      <Button
        small
        danger
        icon={<TrashIcon />}
        onClick={() => {
          if (window.confirm('Are you sure?')) {
            deleteEditRule(id);
          }
        }}
      >
        Delete
      </Button>
    </div>
  );
};

const columns = [
  {
    id: 1,
    Header: 'Type',
    accessor: 'type',
    Cell: ToRuleLabel
  },
  {
    id: 2,
    Header: 'Organizational',
    accessor: 'apply_to_organization',
    Cell: CheckOrCrossIcon
  },
  {
    id: 3,
    Header: 'Enabled',
    accessor: 'enabled',
    Cell: CheckOrCrossIcon
  },
  {
    id: 4,
    Cell: ActionButton,
    idAccessor: 'id'
  }
];

export const EditorRulesList = () => {
  const { data: allEditRules, isLoading } = useGetEditorRules();

  if (isLoading) {
    return <></>;
  }

  if (isEmpty(allEditRules)) {
    return <EditorRulesEmpty />;
  }

  return (
    <>
      <EditorRulesHeader />
      <Table columns={columns} data={allEditRules} />
    </>
  );
};

const NewEditRuleButton = () => (
  <MenuButton
    title="Create"
    items={Object.entries(EditorRulesLabels).map(([editorRuleType, rankerLabelInfo]) => ({
      name: rankerLabelInfo.label,
      route: generatePath(routes.CONFIGURATIONS_EDITOR_RULE_NEW, { editorRuleType })
    }))}
  />
);

const EditorRulesHeader = () => {
  return (
    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Editor Rules</h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          <div className="text-right">
            <NewEditRuleButton />
          </div>
        </div>
      </div>
    </div>
  );
};

const EditorRulesEmpty = () => {
  return (
    <div className="h-72 flex flex-col justify-center items-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-md font-medium text-gray-900">No Editor Rules</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating an editor configuration rule.</p>
      <div className="mt-6">
        <NewEditRuleButton />
      </div>
    </div>
  );
};
