export enum PostProcessExecutionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE'
}

export type PostProcessExecutionReport = {
  start_time: string;
  end_time: string;
  post_process_type: string;
  additional_info: { [key: string]: any };
  status: PostProcessExecutionStatus;
};
