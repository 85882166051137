import { Dialog, Transition } from '@headlessui/react';
import { Button } from '@src/components';
import { useCreateBackgroundJob } from '@src/hooks';
import {
  AnyBackgroundJob,
  BackgroundJobType,
  ElasticCloneExportJob,
  PipelineMigrationJob,
  TransactionConflictJob,
  SuggestSearchKeysJob,
  RequestHeadersCompareJob,
  ProcessCloneHostnamesJob,
  DisableDuplicatedAssetsJob,
  DelteckMaconomyChartStateJob,
  TransactionValidationJob
} from '@src/models';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { ElasticCloneExportModal } from './ElasticCloneExportModal';
import { PipelineMigrationModal } from './PipelineMigrationModal';
import { TransactionConflictModal } from './TransactionConflictModal';
import { SuggestSearchKeysModal } from './SuggestSearchKeysModal';
import { RequestHeadersCompareModal } from './RequestHeadersCompareModal';
import { ProcessCloneHostnamesModal } from './ProcessCloneHostnamesModal';
import { DisableDuplicatedAssetsModal } from './DisableDuplicatedAssetsModal';
import { TransactionValidationModal } from './TransactionValidation';
import { DelteckMaconomyChartStateModal } from './DelteckMaconomyChartState';

export type BackgroundJobModalProps = {
  jobType: BackgroundJobType;
  show: boolean;
  closeModal: () => void;
};

export const BackgroundJobModal = ({ jobType, show, closeModal }: BackgroundJobModalProps) => {
  const [jobModel, setJobModel] = useState<AnyBackgroundJob | null>(null);
  const { mutateAsync: runJob } = useCreateBackgroundJob();

  return (
    <Transition.Root show={show} as={Fragment} appear>
      <Dialog as="div" className="relative z-30 inset-0" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
              <div className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    jobModel &&
                      runJob(jobModel).finally(() => {
                        setJobModel(null);
                        closeModal();
                      });
                  }}
                >
                  <>
                    {jobType === BackgroundJobType.PipelineMigration && (
                      <PipelineMigrationModal
                        jobModel={jobModel as PipelineMigrationJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                    {jobType === BackgroundJobType.TransactionConflict && (
                      <TransactionConflictModal
                        jobModel={jobModel as TransactionConflictJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                    {jobType === BackgroundJobType.ElasticCloneExport && (
                      <ElasticCloneExportModal
                        jobModel={jobModel as ElasticCloneExportJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                    {jobType === BackgroundJobType.SuggestSearchKeys && (
                      <SuggestSearchKeysModal
                        jobModel={jobModel as SuggestSearchKeysJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                    {jobType === BackgroundJobType.RequestHeadersCompare && (
                      <RequestHeadersCompareModal
                        jobModel={jobModel as RequestHeadersCompareJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                    {jobType === BackgroundJobType.ProcessCloneHostnames && (
                      <ProcessCloneHostnamesModal
                        jobModel={jobModel as ProcessCloneHostnamesJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                    {jobType === BackgroundJobType.DisableDuplicatedAssets && (
                      <DisableDuplicatedAssetsModal
                        jobModel={jobModel as DisableDuplicatedAssetsJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                    {jobType === BackgroundJobType.TransactionValidation && (
                      <TransactionValidationModal
                        jobModel={jobModel as TransactionValidationJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                     {jobType === BackgroundJobType.DelteckMaconomyChartState && (
                      <DelteckMaconomyChartStateModal
                        jobModel={jobModel as DelteckMaconomyChartStateJob}
                        setJobModel={job => setJobModel(job)}
                      />
                    )}
                  </>

                  <div className="p-3 flex justify-between">
                    <Button secondary onClick={() => closeModal()}>
                      Close
                    </Button>
                    <Button
                      type="submit"
                      disabled={jobModel === null}
                      className={classNames(jobModel === null && 'opacity-50 cursor-not-allowed')}
                    >
                      Run
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
