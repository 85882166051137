import { Statistics, TransactionList } from '@src/components';

export const TransactionListPage = () => {
  return (
    <div>
      <div className="my-5">
        <Statistics />
      </div>
      <TransactionList />
    </div>
  );
};
