import { FormEvent, useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import { useNavigate, useParams } from 'react-router';
import { useCreateEditorRule, useGetEditorRuleById, useUpdateEditorRule } from '@src/hooks';
import { EditorRulesType, QueryParamsEditorRuleModel } from '@src/models';
import classNames from 'classnames';
import routes from '@src/routes';

type QueryParamsEditorRuleProps = {
  isEdit?: boolean;
};

export const QueryParamsEditRule = ({ isEdit }: QueryParamsEditorRuleProps) => {
  const navigate = useNavigate();
  const { id: ruleId } = useParams() as { id: string };
  const { mutate: saveNewEditRule } = useCreateEditorRule();
  const { mutate: updateEditRule } = useUpdateEditorRule();
  const { data: editorRule, isLoading } = useGetEditorRuleById(ruleId);

  const [editorQueryParamsRule, setRule] = useState<QueryParamsEditorRuleModel>({
    id: '',
    type: EditorRulesType.QueryParamsRule,
    apply_to_organization: true,
    enabled: true,
    config: ''
  });

  useEffect(() => {
    if (!editorRule) return;

    editorRule.config = JSON.stringify(editorRule.config, undefined, 4);

    setRule(editorRule as QueryParamsEditorRuleModel);
  }, [editorRule]);

  const saveRule = (e: FormEvent) => {
    e.preventDefault();

    if (editorQueryParamsRule.config === '') {
      alert('Please add a JSON ruleset');
      return;
    }

    try {
      editorQueryParamsRule.config = JSON.parse(editorQueryParamsRule.config);
    } catch {
      alert('Incorrect JSON format');
      return;
    }

    if (isEdit) {
      updateEditRule(editorQueryParamsRule);
    } else {
      saveNewEditRule(editorQueryParamsRule);
    }

    navigate(routes.CONFIGURATIONS_EDITOR_RULES);
  };

  if (isLoading) return <></>;

  function textareaHandler(e: any) {
    setRule({
      ...editorQueryParamsRule,
      config: e.target.value
    });
  }

  return (
    <form onSubmit={saveRule}>
      <div className="space-y-6">
        <div className="flex flex-col justify-between gap-4">
          <div>
            <h1 className="text-lg leading-6 font-medium text-gray-900">Editor QueryParams Rule</h1>
            <p className="mt-1 text-sm text-gray-500">
              Configure editor custom rules in a JSON format that would be deployed against relevant organization /
              clone, and a domain pattern.
            </p>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Rule JSON templates</h3>
            <p className="mt-1 text-sm text-gray-500">{`1. {"queryParams": ["foo1", "foo2"], "domains": ["foo.com"]}`}</p>
            <p className="mt-1 text-sm text-gray-500">{`2. {"queryParams": ["foo1", "foo2"], "domains": []}`}</p>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="w-3/4 md:w-1/2">
            <label htmlFor="domain-pattern" className="block text-sm font-medium text-gray-700"></label>
            <div className="mt-1"></div>
          </div>
          <Switch.Group className="mt-2" as="div">
            <Switch.Label className="block text-sm font-medium text-gray-700 -mt-1" passive>
              Enabled
            </Switch.Label>
            <dd className="pt-2 text-sm text-gray-900 sm:mt-1 float-right">
              <Switch
                checked={editorQueryParamsRule.enabled || false}
                onChange={(checked: boolean) => setRule({ ...editorQueryParamsRule, enabled: checked })}
                className={classNames(
                  editorQueryParamsRule.enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    editorQueryParamsRule.enabled ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </dd>
          </Switch.Group>
        </div>

        <div className="flex items-center justify-between">
          <div className="w-3/4 md:w-1/2">
            <label htmlFor="domain-pattern" className="block text-sm font-medium text-gray-700"></label>
            <div className="mt-1"></div>
          </div>

          <Switch.Group as="div">
            <Switch.Label className="block text-sm font-medium text-gray-700 -mt-1" passive>
              Organizational
            </Switch.Label>
            <dd className="pt-2 text-sm text-gray-900 sm:mt-1 float-right">
              <Switch
                checked={editorQueryParamsRule.apply_to_organization || false}
                onChange={(checked: boolean) => setRule({ ...editorQueryParamsRule, apply_to_organization: checked })}
                className={classNames(
                  editorQueryParamsRule.apply_to_organization ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    editorQueryParamsRule.apply_to_organization ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </dd>
          </Switch.Group>
        </div>

        <div className="mt-1">
          <textarea
            rows={4}
            name="Editor QueryParams Rule"
            id="QueryParamsRule"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            value={editorQueryParamsRule.config}
            onChange={textareaHandler}
          />
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            onClick={() => navigate(routes.CONFIGURATIONS_EDITOR_RULES)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
