import { useState } from 'react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { Button, Checkbox, DataPreviewer } from '@src/components';

type SingleFileUploadProps = {
  onFileUploaded: (file: File, applyToAllTransactions: boolean) => void;
};

export const SingleFileUpload = ({ onFileUploaded }: SingleFileUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [applyToAllTransactions, setApplyToAllTransactions] = useState(true);

  return (
    <div className="flex flex-col mt-1 px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      {file ? (
        <>
          <DataPreviewer replaceable={false} content={file} headers={{ 'content-type': file?.type }} />

          <div className="flex flex-col items-center mt-4">
            <div className="flex justify-center items-center my-2 gap-4">
              <Button secondary onClick={() => setFile(null)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onFileUploaded(file, applyToAllTransactions);
                  setFile(null);
                }}
              >
                Replace
              </Button>
            </div>
            <Checkbox
              label="Apply to all transactions"
              isChecked={applyToAllTransactions}
              onChange={isChecked => setApplyToAllTransactions(isChecked)}
            />
          </div>
        </>
      ) : (
        <div className="space-y-1 text-center">
          <ArrowUpTrayIcon className="mx-auto h-12 w-12 text-gray-400" />
          <div className="flex justify-center text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                multiple={false}
                onChange={e => {
                  const newFiles = e.target.files;
                  if (newFiles && newFiles.length > 0) {
                    setFile(newFiles[0]);
                  }
                }}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">All file types are supported.</p>
        </div>
      )}
    </div>
  );
};
