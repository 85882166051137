import { Button } from '@src/components';
import { TransportOptionsTree } from './TransportOptionsTree';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useExportConfiguration, useGetExportOptions } from '@src/hooks';
import { useState } from 'react';
import { StudioTransportOption } from '@src/models';

export const ConfigurationExport = () => {
  const [exportOptions, setExportOptions] = useState<StudioTransportOption[] | undefined>(undefined);
  const { mutate: exportConfiguration } = useExportConfiguration();

  const { data } = useGetExportOptions();

  return (
    <div className="flex flex-col">
      <div className="rounded-md pt-7 mb-5 px-7 border border-gray-200 relative">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Export Configuration</h3>
              <p className="mt-1 text-sm text-gray-500">Export chosen configuration as a JSON file...</p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <Button icon={<ArrowDownTrayIcon />} onClick={() => exportOptions && exportConfiguration(exportOptions)}>
                Export
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center p-6">
          {data && <TransportOptionsTree data={data} onOptionsChange={newOptions => setExportOptions(newOptions)} />}
        </div>
      </div>
    </div>
  );
};
