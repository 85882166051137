type ImagePreviewerProps = {
  content: Blob;
  contentType?: string;
};

export const ImagePreviewer = ({ content, contentType }: ImagePreviewerProps) => {
  return (
    <>
      <p className="italic">{contentType}</p>
      <img className="self-center max-h-96 max-w-96 w-96" alt="" src={URL.createObjectURL(content)} />
    </>
  );
};
