import { BoltIcon } from '@heroicons/react/24/solid';
import { Input } from '@src/components';
import { useGetRecordingDetails } from '@src/hooks';
import { BackgroundJobType, PipelineMigrationJob, PipelineMigrationJobParams } from '@src/models';
import { useCallback, useEffect, useState } from 'react';

type PipelineMigrationModalProps = {
  jobModel: PipelineMigrationJob;
  setJobModel: (job: PipelineMigrationJob | null) => void;
};

export const PipelineMigrationModal = ({ setJobModel }: PipelineMigrationModalProps) => {
  const { data: recordingDetails } = useGetRecordingDetails();

  const [jobParams, setJobParams] = useState<PipelineMigrationJobParams>({
    pipeline_id: '',
    recording_id: ''
  });

  const validateParams = useCallback(() => {
    return !!jobParams.pipeline_id;
  }, [jobParams]);

  useEffect(() => {
    if (recordingDetails && validateParams()) {
      setJobModel({
        type: BackgroundJobType.PipelineMigration,
        params: {
          ...jobParams,
          recording_id: recordingDetails.recording_id
        }
      });
    } else {
      setJobModel(null);
    }
  }, [jobParams, recordingDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="group flex cursor-default select-none pb-6 p-3">
        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
          <BoltIcon className="h-6 w-6 text-white" />
        </div>
        <div className="ml-4 flex-auto">
          <p className="text-sm font-medium text-gray-700">Pipeline Migration</p>
          <p className="text-sm text-gray-500">
            Pipeline migration job will run a given pipeline id on all existing transactions for this clone.
          </p>
        </div>
      </div>
      <div className="px-4 py-5 space-y-5">
        <fieldset>
          <div className="relative flex pb-5">
            <div className="flex items-center w-full h-5">
              <Input
                className="w-full"
                label="Pipeline ID"
                type="text"
                name="pipeline-id"
                id="pipeline-id"
                value={jobParams.pipeline_id}
                onChange={e => setJobParams({ ...jobParams, pipeline_id: e.target.value })}
              />
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
};
