import { Link, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon as BackIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Children, PropsWithChildren, cloneElement } from 'react';
import classNames from 'classnames';

export type BreadcrumbItemProps = {
  to: string;
  label: string;
  isFirstItem?: boolean;
};

export const BreadcrumbItem = ({ to, label, isFirstItem }: BreadcrumbItemProps) => {
  return (
    <li>
      <div className="flex items-center">
        {!isFirstItem && <ChevronRightIcon className="shrink-0 h-5 w-5 text-gray-400" />}
        <Link
          to={to}
          className={classNames('text-sm font-medium text-gray-500 hover:text-gray-700', !isFirstItem && 'ml-4')}
        >
          {label}
        </Link>
      </div>
    </li>
  );
};

export const Breadcrumb = ({ children }: PropsWithChildren<any>) => {
  const navigate = useNavigate();

  return (
    <>
      <nav className="sm:hidden ml-2" aria-label="Back">
        {navigate.length > 0 && (
          <button
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={() => navigate(-1)}
          >
            <BackIcon className="shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" />
            Back
          </button>
        )}
      </nav>
      <nav className="hidden sm:flex ml-6 xl:ml-0" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {Children.map(children, (child, index) => {
            return index === 0 ? cloneElement(child, { isFirstItem: true }) : child;
          })}
        </ol>
      </nav>
    </>
  );
};
