import classNames from 'classnames';
import { ReactNode } from 'react';

type CheckboxProps = {
  label: ReactNode;
  description?: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
};

export const Checkbox = ({ label, description, isChecked, onChange, disabled }: CheckboxProps) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          disabled={disabled}
          className={classNames(
            'h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500',
            disabled && 'cursor-not-allowed'
          )}
          checked={isChecked}
          onChange={e => onChange(e.target.checked)}
        />
      </div>
      <div className="ml-3 text-sm">
        <label className="font-medium text-gray-700">{label}</label>
        {description && <p className="text-gray-500">{description}</p>}
      </div>
    </div>
  );
};
