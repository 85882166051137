export enum EditorRulesType {
  GenericRule = 'genericRule',
  QueryParamsRule = 'queryParams',
  ExternalDomTreeScoreThresholdRule = 'externalDomTreeScoreThreshold'
}

export const EditorRulesLabels: { [key: string]: { label: string; color: string } } = {
  [EditorRulesType.GenericRule]: {
    label: 'Generic Editor Rule',
    color: 'blue'
  },
  [EditorRulesType.QueryParamsRule]: {
    label: 'QueryParams Rule',
    color: 'green'
  },
  [EditorRulesType.ExternalDomTreeScoreThresholdRule]: {
    label: 'DOM Tree Score Threshold Rule',
    color: 'purple'
  }
};

export type EditorRule<T extends EditorRulesType> = {
  id: string;
  type: T;
  config: string;
  apply_to_organization?: boolean;
  enabled?: boolean;
};

export type GenericEditorRuleModel = EditorRule<EditorRulesType.GenericRule>;
export type QueryParamsEditorRuleModel = EditorRule<EditorRulesType.QueryParamsRule>;
export type ExternalDomTreeScoreThresholdModel = EditorRule<EditorRulesType.ExternalDomTreeScoreThresholdRule>;
export type AnyEditorRule = GenericEditorRuleModel | QueryParamsEditorRuleModel | ExternalDomTreeScoreThresholdModel; // We can use this to extend the rule types
