import request, { apiRequest } from '../request';
import { AnyBackgroundJob } from '@src/models';

const API_ENDPOINTS = {
  BACKGROUND_JOBS: '/jobs'
};

export const getBackgroundJobs = async (): Promise<AnyBackgroundJob[]> =>
  (await apiRequest(request.get(API_ENDPOINTS.BACKGROUND_JOBS))).content;

export const getBackgroundJobById = async (jobId: string): Promise<AnyBackgroundJob | undefined> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.BACKGROUND_JOBS}/${jobId}`))).content;

export const createBackgroundJob = async (job: AnyBackgroundJob): Promise<AnyBackgroundJob> => {
  return (await apiRequest(request.post(API_ENDPOINTS.BACKGROUND_JOBS, job))).content;
};
