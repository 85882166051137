import { Breadcrumb, BreadcrumbItem, TransactionRankingList } from '@src/components';
import routes from '@src/routes';

export const TransactionRankersListPageBreadCrumb = () => {
  return (
    <Breadcrumb>
      <BreadcrumbItem to={routes.CONFIGURATIONS} label="Configurations" />
      <BreadcrumbItem to={routes.CONFIGURATIONS_RANKING} label="Transaction Ranking" />
    </Breadcrumb>
  );
};

export const TransactionRankersListPage = () => {
  return (
    <>
      <TransactionRankersListPageBreadCrumb />
      <div className="h-full py-6">
        <TransactionRankingList />
      </div>
    </>
  );
};
