import { Notification } from '@src/components';
import { useNotifications } from '@src/hooks';

export const NotificationContainer = () => {
  const { notifications, clearNotification } = useNotifications();

  return (
    <div className="fixed inset-0 pointer-events-none bg-transparent flex flex-col justify-end sm:justify-start z-50">
      {notifications.map((notification, index) => (
        <Notification
          key={`notification-${notification.id}`}
          notification={notification}
          index={index}
          onClose={id => clearNotification(id)}
        />
      ))}
    </div>
  );
};
