import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import '@src/index.css';
import App from '@src/App';
import reportWebVitals from '@src/reportWebVitals';
import { useNotifications } from '@src/hooks';
import { AxiosError } from 'axios';

const root = ReactDOM.createRoot(document.getElementById('studio-root') as HTMLElement);

const useReactQueryClient = () => {
  const { notifyError } = useNotifications();

  const [queryClient] = React.useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          onError: (e: any) => {
            if (e instanceof AxiosError) {
              switch (e.status || 500) {
                case 500: {
                  notifyError({ title: `${e.message || e}` });
                  return;
                }
              }
            }
          }
        },
        mutations: {
          retry: false
        }
      }
    });
  });

  return queryClient;
};

const Root = () => {
  const queryClient = useReactQueryClient();

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
