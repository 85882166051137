import { ConfigurationExport, ConfigurationImport } from '@src/components';

export const ConfigurationTransport = () => {
  return (
    <div className="flex w-full justify-center ml-12 lg:ml-0">
      <div className="w-full lg:w-10/12">
        <ConfigurationExport />
        <ConfigurationImport />
      </div>
    </div>
  );
};
