import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { generatePath, useNavigate } from 'react-router';
import { Button, CheckOrCrossIcon, MenuButton, Table } from '@src/components';
import {
  useCreateSequentialStateRules,
  useDeleteTransactionRanker,
  useGetPostProcessingReport,
  useGetTransactionRankers
} from '@src/hooks';
import {
  AnyTransactionRanker,
  PostProcessExecutionStatus,
  TransactionRankerLabels,
  TransactionRankerType
} from '@src/models';
import { isEmpty } from '@src/utils';
import routes from '@src/routes';

const getRuleLabel = () => {
  // Memoized
  const ruleComponents = Object.fromEntries(
    Object.entries(TransactionRankerLabels).map(([rankerRule, rankerLabelInfo]) => {
      return [
        rankerRule,
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${rankerLabelInfo.color}-100 text-${rankerLabelInfo.color}-800`}
        >
          {rankerLabelInfo.label}
        </span>
      ];
    })
  );

  const rankerRuleCell = ({ cell }: any) => {
    return ruleComponents[cell.value as TransactionRankerType];
  };

  return rankerRuleCell;
};

const ActionButton = ({ row }: any) => {
  const id = (row.original as AnyTransactionRanker).id;
  const rankerType = (row.original as AnyTransactionRanker).type;
  const navigate = useNavigate();
  const { mutate: deleteRanker } = useDeleteTransactionRanker();
  return (
    <div className="flex justify-end gap-4">
      <Button
        small
        secondary
        icon={<PencilSquareIcon />}
        onClick={() => navigate(generatePath(routes.CONFIGURATIONS_RANKING_RULE_EDIT, { rankerType, id }))}
      >
        Edit
      </Button>
      <Button
        small
        danger
        icon={<TrashIcon />}
        onClick={() => {
          if (window.confirm('Are you sure?')) {
            deleteRanker(id);
          }
        }}
      >
        Delete
      </Button>
    </div>
  );
};

const columns = [
  {
    id: 1,
    Header: 'Type',
    accessor: 'type',
    Cell: getRuleLabel()
  },
  {
    id: 2,
    Header: 'Domain Pattern',
    accessor: 'domain_pattern'
  },
  {
    id: 3,
    Header: 'Organizational',
    accessor: 'apply_to_organization',
    Cell: CheckOrCrossIcon
  },
  {
    id: 4,
    Header: 'Enabled',
    accessor: 'enabled',
    Cell: CheckOrCrossIcon
  },
  {
    id: 5,
    Header: 'Automated',
    accessor: 'automated',
    Cell: CheckOrCrossIcon
  },
  {
    id: 6,
    Header: 'Jira Ticket',
    accessor: 'ticket_id'
  },
  {
    id: 7,
    Cell: ActionButton,
    idAccessor: 'id'
  }
];

export const TransactionRankingList = () => {
  const { data: allRankingRules, isLoading } = useGetTransactionRankers();

  if (isLoading) {
    return <></>;
  }

  if (isEmpty(allRankingRules)) {
    return <TransactionRankingEmpty />;
  }

  return (
    <>
      <TransactionRankingHeader />
      <Table enablePagination columns={columns} data={allRankingRules} />
    </>
  );
};

const NewRankerButton = () => (
  <MenuButton
    title="Create"
    items={Object.entries(TransactionRankerLabels).map(([rankerType, rankerLabelInfo]) => ({
      name: rankerLabelInfo.label,
      route: generatePath(routes.CONFIGURATIONS_RANKING_RULE_NEW, { rankerType })
    }))}
  />
);

const AutoGenerateRulesButton = () => {
  const { data: postProcessReport } = useGetPostProcessingReport();
  const { mutate: createSequentialStateRules } = useCreateSequentialStateRules();

  return (
    <Button
      className={postProcessReport?.status !== PostProcessExecutionStatus.DONE ? 'hidden' : ''}
      secondary
      onClick={() => createSequentialStateRules()}
    >
      Auto generate state rules
    </Button>
  );
};

const TransactionRankingHeader = () => {
  return (
    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Transaction Ranking Rules</h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          <div className="flex items-center gap-4">
            <AutoGenerateRulesButton />
            <NewRankerButton />
          </div>
        </div>
      </div>
    </div>
  );
};

const TransactionRankingEmpty = () => {
  return (
    <div className="h-72 flex flex-col justify-center items-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-md font-medium text-gray-900">No transaction rankers</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new ranker.</p>
      <div className="flex flex-col items-center gap-4 mt-6">
        <NewRankerButton />
        <AutoGenerateRulesButton />
      </div>
    </div>
  );
};
