import { Navigate, useRoutes } from 'react-router-dom';
import { Dashboard } from '@src/components';
import {
  TransactionDetailsPage,
  TransactionListPage,
  ConfigurationPage,
  TransactionRankerPage,
  TransactionRankersListPage,
  EditorRulePage,
  EditorRulesListPage,
  RankedTransactionListPage,
  DynamicContentModPreviewPage,
  DynamicContentModDetailsPage,
  ConfigurationTransportPage,
  BackgroundJobDetailsPage,
  BackgroundJobsPage,
  TransactionValidationDiffPage
} from '@src/pages';
import routes from '@src/routes';
import { CacheListPage } from './pages/Cache';

export default function App() {
  return useRoutes([
    { path: '/', element: <Navigate replace to={routes.TRANSACTIONS} /> },
    {
      path: routes.TRANSACTIONS,
      element: <Dashboard />,
      children: [
        { path: '', element: <TransactionListPage /> },
        { path: 'ranked', element: <RankedTransactionListPage /> },
        { path: ':id', element: <TransactionDetailsPage /> },
        { path: 'diff/:leftId/:rightId', element: <TransactionValidationDiffPage /> }
      ]
    },
    {
      path: routes.CACHE,
      element: <Dashboard />,
      children: [{ path: '', element: <CacheListPage /> }]
    },
    {
      path: routes.BACKGROUND_JOBS,
      element: <Dashboard />,
      children: [
        { path: '', element: <BackgroundJobsPage /> },
        { path: ':id', element: <BackgroundJobDetailsPage /> }
      ]
    },
    {
      path: routes.CONFIGURATIONS,
      element: <Dashboard />,
      children: [
        { path: '', element: <ConfigurationPage /> },
        { path: 'ranking/:rankerType/:id/edit', element: <TransactionRankerPage isEdit /> },
        { path: 'ranking/:rankerType/new', element: <TransactionRankerPage /> },
        { path: 'pipeline', element: <DynamicContentModPreviewPage /> },
        { path: 'pipeline/:id/edit', element: <DynamicContentModDetailsPage isEdit /> },
        { path: 'pipeline/new', element: <DynamicContentModDetailsPage /> },
        { path: 'editor/:editorRuleType/:id/edit', element: <EditorRulePage isEdit /> },
        { path: 'editor/:editorRuleType/new', element: <EditorRulePage /> },
        {
          path: 'ranking',
          element: <TransactionRankersListPage />
        },
        {
          path: 'editor',
          element: <EditorRulesListPage />
        },
        { path: 'export', element: <ConfigurationTransportPage /> }
      ]
    },
    { path: '*', element: <Navigate replace to={routes.TRANSACTIONS} /> }
  ]);
}
