import { ReactNode } from 'react';
import { Notification, NotificationType } from '@src/models';
import { getGlobalState, State, useGlobalState } from '@src/state';
import { v4 as uuidv4 } from 'uuid';

type NotificationInfo = {
  title: ReactNode;
  content?: ReactNode;
  timeout?: number;
};

export const useNotifications = () => {
  const [notifications, setNotifications] = useGlobalState(State.NOTIFICATIONS);

  const getNotifications = () => getGlobalState(State.NOTIFICATIONS);

  const newNotification = (type: NotificationType, title: ReactNode, content?: ReactNode, timeout?: number) => {
    const notification: Notification = {
      id: uuidv4(),
      type,
      title,
      content
    };

    setNotifications([
      ...getNotifications().filter(
        n => notification.title !== n.title || notification.type !== n.type || notification.content !== n.content
      ),
      notification
    ]);

    timeout &&
      setTimeout(() => {
        clearNotification(notification.id);
      }, timeout);

    return notification;
  };

  const clearNotification = (id: string) => {
    const updatedNotifications = getNotifications().filter(n => n.id !== id);
    setNotifications(updatedNotifications);
  };

  return {
    notifications,
    clearNotification,

    notifySuccess: ({ title, content, timeout }: NotificationInfo) =>
      newNotification('success', title, content, timeout),

    notifyWarn: ({ title, content, timeout }: NotificationInfo) => newNotification('warning', title, content, timeout),

    notifyError: ({ title, content, timeout }: NotificationInfo) => newNotification('error', title, content, timeout)
  };
};
