import { PostProcessExecutionReport } from '@src/models';
import { createSequentialStateRules, getPostProcessingReport, startPostProcessing } from '@src/services';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNotifications } from './notification';

export const useGetPostProcessingReport = () => {
  const { data, isLoading, error } = useQuery<PostProcessExecutionReport | null>('post-processing-report', async () => {
    try {
      return await getPostProcessingReport();
    } catch {
      return null;
    }
  });

  return { data, isLoading, error };
};

export const useStartPostProcessing = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(() => {
    notifySuccess({
      title: 'Clone analysis started!',
      timeout: 2000
    });

    return startPostProcessing()
      .then(response => {
        notifySuccess({
          title: 'Clone analysis is done!',
          timeout: 4000
        });

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Clone analysis failed!',
          content: e['detail']
        });

        throw e;
      })
      .finally(() => queryClient.invalidateQueries('post-processing-report'));
  });

  return { mutate, isLoading, error };
};

export const useCreateSequentialStateRules = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(() =>
    createSequentialStateRules()
      .then(response => {
        notifySuccess({
          title: 'Workflow Rules created.',
          timeout: 4000
        });

        queryClient.invalidateQueries('ranking-rules');

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Could not create Workflow Rules.',
          content: e.response.data['detail']
        });

        throw e;
      })
  );

  return { mutate, isLoading, error };
};
