import { BoltIcon } from '@heroicons/react/24/solid';
import { useGetRecordingDetails } from '@src/hooks';
import { BackgroundJobType, TransactionConflictJob } from '@src/models';
import { useEffect } from 'react';

type TransactionConflictModalProps = {
  jobModel: TransactionConflictJob;
  setJobModel: (job: TransactionConflictJob) => void;
};

export const TransactionConflictModal = ({ setJobModel }: TransactionConflictModalProps) => {
  const { data: recordingDetails } = useGetRecordingDetails();

  useEffect(() => {
    if (recordingDetails) {
      setJobModel({
        type: BackgroundJobType.TransactionConflict,
        params: {
          recording_id: recordingDetails.recording_id
        }
      });
    }
  }, [recordingDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="group flex cursor-default select-none pb-6 p-3">
        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
          <BoltIcon className="h-6 w-6 text-white" />
        </div>
        <div className="ml-4 flex-auto">
          <p className="text-sm font-medium text-gray-700">Workflows Analyzer</p>
          <p className="text-sm text-gray-500">Some description about transaction conflict job</p>
        </div>
      </div>
    </>
  );
};
