import { useGetRecordingStatistics } from '@src/hooks';
import { formatByteSize } from '@src/utils';

export const Statistics = () => {
  const { data: recordingStatistics } = useGetRecordingStatistics();

  const stats = [
    { name: 'Total Transactions', stat: recordingStatistics?.num_of_transactions },
    {
      name: 'Active Sessions',
      stat: recordingStatistics?.active_sessions,
      description: 'Sessions using this recording in the last 2 days'
    },
    {
      name: 'Total Transactions Size',
      stat: formatByteSize(recordingStatistics?.transactions_size)
    }
  ];

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map(item => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                {item.description && <span className="ml-2 text-sm font-medium text-gray-500">{item.description}</span>}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
