import { Checkbox } from '@src/components';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { StudioTransportOption } from '@src/models';
import { ReactNode, useEffect, useState } from 'react';

type TransportOptionsTreeProps = {
  data: StudioTransportOption[];
  onOptionsChange: (options: StudioTransportOption[]) => void;
};

export const TransportOptionsTree = ({ data, onOptionsChange }: TransportOptionsTreeProps) => {
  const [selectedOptions, setSelectedOptions] = useState<StudioTransportOption[]>();

  const updateOption = (optionClicked: StudioTransportOption, isChecked: boolean) => {
    const selectedOptionsCopy: StudioTransportOption[] = JSON.parse(JSON.stringify(selectedOptions));
    selectedOptionsCopy.forEach(co => {
      const shouldBeChanged = optionClicked.id === co.id;
      if (shouldBeChanged) {
        co.checked = isChecked;
      }

      updateChildren(co, optionClicked.id, shouldBeChanged, isChecked);
      co.checked =
        !!co.children.length && co.children.map(c => c.checked).reduce((result, current) => result && current, true);
    });

    setSelectedOptions(selectedOptionsCopy);
  };

  const updateChildren = (
    option: StudioTransportOption,
    updatedId: string,
    isParentChanged: boolean,
    isChecked: boolean
  ) => {
    option.children.forEach(co => {
      const shouldBeChanged = isParentChanged || co.id === updatedId;
      if (shouldBeChanged) {
        co.checked = isChecked;
      }

      updateChildren(co, updatedId, shouldBeChanged, isChecked);
    });
  };

  useEffect(() => {
    setSelectedOptions(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    selectedOptions && onOptionsChange(selectedOptions);
  }, [selectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedOptions) return null;

  return <fieldset>{selectedOptions.map(o => renderOption(o, updateOption))}</fieldset>;
};

export const TransportOptionLink = ({ name, link, ticketId }: { name: string; link: string; ticketId?: string }) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    className="flex items-center cursor-pointer hover:text-indigo-700 gap-1"
  >
    {name} {ticketId ? `[${ticketId.toUpperCase()}]` : null}
    <ArrowTopRightOnSquareIcon className="w-5 h-5" />
  </a>
);

const renderLabel = (option: StudioTransportOption): ReactNode => {
  if (!option.link) {
    return option.name;
  }

  return <TransportOptionLink name={option.name} link={option.link} ticketId={option.ticket_id} />;
};

const renderOption = (
  option: StudioTransportOption,
  updateOption: (optionClicked: StudioTransportOption, isChecked: boolean) => void
) => {
  return (
    <div key={`to-${option.id}`}>
      <Checkbox
        label={renderLabel(option)}
        isChecked={option.checked}
        onChange={checked => updateOption(option, checked)}
        disabled={option.children.length === 0 && !option.is_resource}
      />
      {option.children.length > 0 ? (
        <>
          {option.children.map(co => (
            <div key={`to-${option.id}-${co.id}`} className="ml-6 my-2">
              {renderOption(co, updateOption)}
            </div>
          ))}
        </>
      ) : (
        !option.is_resource && <div className="ml-7 my-2 text-xs text-gray-500">- No data to export</div>
      )}
    </div>
  );
};
