import { cloneElement, isValidElement, ReactNode } from 'react';
import classNames from 'classnames';
import { SignalIcon } from '@heroicons/react/24/solid';

export type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  loading?: boolean;
  secondary?: boolean;
  danger?: boolean;
  small?: boolean;
  icon?: ReactNode;
};

export const Button = ({
  className,
  secondary,
  danger,
  small,
  type,
  disabled,
  loading,
  children,
  icon,
  ...rest
}: ButtonProps) => (
  <button
    {...rest}
    disabled={disabled}
    type={type || 'button'}
    className={classNames(
      'inline-flex items-center border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
      small ? 'px-2 py-1' : children ? 'px-4 py-2' : 'px-2 py-2',
      secondary ? 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50' : 'border-transparent text-white',
      !secondary && !danger && 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
      !secondary && danger && 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
      disabled && 'disabled:opacity-50 disabled:cursor-not-allowed',
      loading && 'animate-pulse',
      className
    )}
  >
    {icon &&
      !loading &&
      isValidElement(icon) &&
      cloneElement<any>(icon, {
        className: classNames('h-5 w-5', secondary && 'text-gray-500', children && '-ml-1 mr-2')
      })}
    {loading && <SignalIcon className={classNames('animate-spin h-5 w-5 mr-3', secondary && 'text-gray-500')} />}
    {children}
  </button>
);

export type FileInputButtonProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  loading?: boolean;
  secondary?: boolean;
  danger?: boolean;
  small?: boolean;
  icon?: ReactNode;
};

export const FileInputButton = ({
  className,
  secondary,
  danger,
  small,
  type,
  disabled,
  loading,
  children,
  icon,
  ...rest
}: FileInputButtonProps) => (
  <label
    className={classNames(
      'inline-flex items-center border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer',
      small ? 'px-2 py-1' : children ? 'px-4 py-2' : 'px-2 py-2',
      secondary ? 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50' : 'border-transparent text-white',
      !secondary && !danger && 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
      !secondary && danger && 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
      disabled && 'disabled:opacity-50 disabled:cursor-not-allowed',
      loading && 'animate-pulse',
      className
    )}
  >
    <input {...rest} disabled={disabled} type="file" className="hidden" />
    {icon &&
      !loading &&
      isValidElement(icon) &&
      cloneElement<any>(icon, {
        className: classNames('h-5 w-5', secondary && 'text-gray-500', children && '-ml-1 mr-2')
      })}
    {loading && <SignalIcon className={classNames('animate-spin h-5 w-5 mr-3', secondary && 'text-gray-500')} />}
    {children}
  </label>
);
