import { Outlet, useLocation } from 'react-router';
import { useGetRecordingDetails } from '@src/hooks';
import { Navbar, NavigationItem, NotificationContainer } from '@src/components';
import routes from '@src/routes';
import classNames from 'classnames';

const navigationItems: NavigationItem[] = [
  {
    name: 'Transactions',
    description: 'View and edit all of the recordings transactions',
    route: routes.TRANSACTIONS
  },
  {
    name: 'Configurations',
    description: 'Configuration should be used in order to modify existing or future recordings',
    route: routes.CONFIGURATIONS
  },
  {
    name: 'Debugging',
    description: "Useful tools to understand why a Clone doesn't work correctly",
    route: routes.CONFIGURATIONS
  },
  {
    name: 'Ranked Transactions',
    description: 'This should be moved inside "Debugging". Here for now.',
    route: routes.RANKED_TRANSACTIONS
  },
  {
    name: 'Automated Jobs',
    description: 'Automate workflows, apply new pipelines on existing Clones, etc.',
    route: routes.BACKGROUND_JOBS
  },
  {
    name: 'Cache',
    description: 'View and invalidate recorder cache',
    route: routes.CACHE
  }
];

export const Dashboard = () => {
  const { data: recordingDetails } = useGetRecordingDetails();
  const location = useLocation();

  const isDiffView = location.pathname.includes('diff');

  return (
    <div className="w-full h-full">
      <Navbar items={navigationItems} info={recordingDetails} />
      <NotificationContainer />
      <main className={classNames('py-5', isDiffView ? 'px-5 w-full' : 'mx-auto max-w-7xl')}>
        <Outlet />
      </main>
    </div>
  );
};
