import { ChevronRightIcon, FunnelIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { AppliedModifierSchema, AppliedSelectorSchema, PipelineSchema } from '@src/models';
import { ReactNode } from 'react';

type DynamicContentModListItemProps = {
  index: number;
  item: PipelineSchema;
  onItemClick: (item: PipelineSchema) => void;
};

export const DynamicContentModListItem = ({ index, item, onItemClick }: DynamicContentModListItemProps) => {
  return (
    <li key={`dynamic-pipeline-list-item-${index}`}>
      <div onClick={() => onItemClick(item)} className="block hover:bg-gray-50 cursor-pointer">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 pr-4">
              <div className="w-full grid grid-flow-col grid-cols-3 justify-between">
                <span className="flex items-center text-sm font-medium text-indigo-500">
                  {item.id} {item.ticketId ? `[${item.ticketId.toUpperCase()}]` : null}
                </span>
                <SelectorModifierList
                  key={`modifier-list-item-${index}`}
                  items={item.selectors}
                  icon={<FunnelIcon className="h-4 w-4" />}
                />
                <SelectorModifierList
                  key={`selector-list-item-${index}`}
                  items={item.modifiers}
                  icon={<PencilSquareIcon className="h-4 w-4" />}
                />
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>
    </li>
  );
};

type SelectorModifierListProps = {
  items: AppliedSelectorSchema[] | AppliedModifierSchema[];
  icon: ReactNode;
  className?: string;
};

const SelectorModifierList = ({ items, icon, className }: SelectorModifierListProps) => {
  return (
    <ul className={className}>
      {items.map((item, idx) => (
        <li
          key={`selector-modifier-item-${item.id}-${idx}`}
          className="flex items-center gap-1 text-sm font-medium text-gray-500"
        >
          {icon} {item.id}
        </li>
      ))}
    </ul>
  );
};
